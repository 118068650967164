import * as React from "react";
import "../detailsPackage/MultiSteps/stepsNum/stepsNumCss/popUp.css";
import { TextField, CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import { useMediaQuery } from "react-responsive";
import createCache from "@emotion/cache";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
import parsePhoneNumber from "libphonenumber-js";
import ContentDetailsOfBusiness from "./ContentDetailsOfBusiness";
import { usePaymentInputs } from "react-payment-inputs";
import { DataContext } from "../../../dataContext";
import { useContext } from "react";

// import PopUpMoreDetails from "./popUpDetails_responsive"
import styled from "@emotion/styled";

import { t } from "i18next";
// import DetailsOfPackage from '../../detailsOfPackage';
import { type } from "@testing-library/user-event/dist/type";
import { set } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

export default function DetailsOfBusiness({}) {
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();
  const [v, setV] = React.useState("");
  const [error, setError] = React.useState("");
  const [cardNumber, setCardNumber] = React.useState("");
  const [expiryDate, setExpiryDate] = React.useState("");
  const [cvc, setCvc] = React.useState("");
  const { open, setOpen, options, setOptions, moveToDetailsOfBusiness, setMoveToDetailsOfBusiness } = useContext(DataContext);
  const navigate = useNavigate();

  const location = useLocation();
  const handleChangeCardNumber = (e) => {
    setCardNumber(e.target.value);
  };
  const handleChangeExpiryDate = (e) => {
    setExpiryDate(e.target.value);
  };
  const handleChangeCvc = (e) => {
    setCvc(e.target.value);
  };
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });

  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });
  const InputLabelProps = {
    display: "flex",
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "25px",
    textAlign: "right",
    color: "#0D152E",
    direction: ltrTheme.direction === "rtl" && "ltr",
  };
  const isRTL = (s) => {
    if (s !== "") {
      var ltrChars =
          "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
          "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
        rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
        rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]");

      return rtlDirCheck.test(s);
    }
    return true;
  };

  const backArrow = () => {
    setMoveToDetailsOfBusiness(false);
    navigate("/connected");
    setOptions("username");
  };

  return (
    <div
      className={
        location.pathname == "/details" ? "background_details_business" : ""
      }
      // style={{height:isMobile && '1310px', margin: "0 auto", background: isMobile && "none", width: "100%" }}>
      style={{
        height: isMobile && "1450px",
        margin: "0 auto",
        background: isMobile && "none",
        width: "100%",
      }}
    >
      {location.pathname == "/details" ? (
        <div
          style={{
            gap: "15px",
            width: "100%",
            transform: isMobile && "none",
            boxShadow: isMobile && "none",
          }}
          className="height_details_business"
        >
          <div
            className="img_back_arrow"
            style={{ cursor: "pointer" }}
            onClick={backArrow}
          >
            <div className="back_arrow"></div>
          </div>
          <ContentDetailsOfBusiness />
        </div>
      ) : (
        <Dialog
          hideBackdrop
          sx={{
            "& .MuiPaper-root": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px 4px 50px",
              gap: "10px",
              height: "1199px",
              maxWidth: "710px",
              width: "100%",
              //    boxShadow:"none",
              //     color:"white",
              transition: "none",
              background: "#FFFFFF",
              borderRadius: "4px",
            },
          }}
          open={open}
          onClose={setOpen}
        >
          <CssBaseline />
          <DialogContent sx={{ width: "100%" }}>
            <ContentDetailsOfBusiness />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
