// import Logo from "../logoHeader";
import pogo from "../images/pogo.png";
import { useState, useEffect } from "react";
import deliverImg from "../images/deliverImg.png";
import { Select } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Arrow from "../images/Arrow color.png";
import { MenuItem } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { Container } from "react-bootstrap";
import { Button } from "@mui/material";
import Login from "../login/login";
import ArrowDown from "../images/arrowDown.png";
import DetailsOfBusiness from "../detailsOfBusiness/details_business";
import "./logo_connect_header.css";
import Profile from "../images/profile.png";
import "../header/header.css";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { DataContext } from "../../../dataContext";
import { useContext } from "react";

export default function LogoConnect() {
  const { t, i18n } = useTranslation();
  const { options, setOptions, validToken, setValidToken, moveToDetailsOfBusiness, setMoveToDetailsOfBusiness } =
    useContext(DataContext);
  const [lng, setLng] = useState(``);
  const [isLogin, setIsLogin] = useState(true);
  const [open, setOpen] = useState(false);
  // const [openDetailsPopUp, setOpenDetailsPopUp] = useState(false)

  const [showMenu, setShowMenu] = useState(false);
  const [showDetailsBusinessInLogoConnect, setShowDetailsBusinessInLogoConnect] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(lng);
    const dir = lng === "en" ? "rtl" : "ltr";
    document.documentElement.dir = dir;
  }, [lng]);

  const navigate = useNavigate();
  const location = useLocation();
  const customer_name = JSON.parse(localStorage.getItem("access_token_client"))
    ? JSON.parse(localStorage.getItem("access_token_client")).customer_name
    : "";
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });
  // console.log(options);

  useEffect(() => {
    setIsSelectOpen(false);
    if (options == "log_out") {
      localStorage.removeItem("access_token_client");
      setValidToken(false);
      setOptions("username");
      navigate("/");
    }
    options == "Businnes details" && navigate("/details");
    options == "history_shippments" && navigate("/history-delivery");

    //  location.pathname == '/history-delivery' && setOptions("history_shippments")
  }, [options]);

  const backArrow = () => {
    setMoveToDetailsOfBusiness(false);
    navigate("/connected");
    setOptions("username");
  };

  const MenuItemStyle = {
    height: "50px",
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#0D152E",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  };
  const logOutColor = {
    height: "50px",
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "22px",
    textAlign: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    color: "#E2291E",
  };

  const showLogo = () => {
    if (!isMobile || location.pathname == "/connected") {
      return true;
    }
  };
  const handelArrow = () => {
    //alert("dd");
    setIsSelectOpen(!isSelectOpen);
  };

  return (
    
    <div className="logo_connect" style={{}}>
      <div
        className="header_logo_connect"
        style={{
          marginInline: !isTabletOrMobile && "80px",
          height: isMobile && "70px",
          top: isMobile && "-6px",
        }}
      >
        <div style={{ display: "flex" }}>
          {!isMobile && (
            <div
              style={{
                position: "",
                width: isMobile && "10%",
                marginTop: isMobile && "6px",
              }}
            >
              <FormControl fullWidth>
                {!isMobile && (
                  <InputLabel id="demo-simple-select-label">
                    Language
                  </InputLabel>
                )}
                <Select
                  labelId="demo-simple-select-label"
                  sx={{
                    "& svg": { color: "#583DFF" },
                    "& legend": { display: isMobile && "none" },
                    "& fieldset": {
                      top: isMobile && 0,

                      display: isMobile && "none",
                    },

                    minWidth: !isMobile ? 120 : 0,
                  }}
                  IconComponent={() => (
                    <img
                      src={ArrowDown}
                      alt="arrow_down"
                      style={{ marginRight: "7px", cursor: "pointer" }}
                    />
                  )}
                  style={{ color: "#583DFF", borderWidth: "0px" }}
                  value={lng}
                  label={!isMobile && "language"}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => setLng(e.target.value)}
                >
                  <MenuItem style={{ color: "#583DFF" }} value={`en`}>
                    English
                  </MenuItem>
                  <MenuItem style={{ color: "#583DFF" }} value={`he`}>
                    עברית
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          {isLogin ? (
            <div
              className={isMobile ? "select_navbar line" : "select_navbar"}
              style={{ flexDirection: isMobile && "row-reverse" }}
              onClick={handelArrow}
            >
              <img src={Profile} alt="profile" />
              <Select
                className="select_arrow"
                labelId="demo-simple-select-label"
                sx={{
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    { paddingRight: "10px" },
                  "& .css-qiwgdb.MuiSelect-select": { paddingRight: "10px" },
                  "& svg": { color: "#583DFF" },
                  "& fieldset": {
                    display: "none",
                  },
                  "& MuiList-root": {
                    height: "400px",
                  },
                }}
                open={isSelectOpen}
                //open={true}
                IconComponent={() => (
                  <img
                    src={ArrowDown}
                    onClick={handelArrow}
                    alt="arrow_down"
                    style={{ marginLeft: "7px", cursor: "pointer" }}
                  />
                )}
                style={{
                  direction:
                    document.documentElement.dir === "rtl" ? "ltr" : "rtl",
                  borderWidth: "0px",
                  // width: 97px;
                  height: "62px",
                  fontFamily: "Heebo",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "15px",
                  lineHeight: "22px",
                  textAlign: "right",
                  color: "#0D152E",
                }}
                value={options}
                label="language"
                inputProps={{ "aria-label": "Without label" }}
                onChange={(e) => setOptions(e.target.value)}
              >
                {/* <MenuItem style={
                  MenuItemStyle
                } value={`usernmae`}>username</MenuItem> */}
                {/* <span className="span_menulist_line" /> */}
                <MenuItem
                  sx={{ display: "none" }}
                  selected
                  style={{ display: "none" }}
                  value={`username`}
                >
                  {" "}
                  {customer_name}
                </MenuItem>

                <MenuItem style={MenuItemStyle} value={`Businnes details`}>
                  {t("BUSINNES_DETAILS")}
                </MenuItem>
                <span className="span_menulist_line" />
                <MenuItem style={MenuItemStyle} value={`history_shippments`}>
                  {t("HISTORY_SHIPPMENTS")}
                </MenuItem>
                <span className="span_menulist_line" />
                <MenuItem style={logOutColor} value={`log_out`}>
                  {t("LOGIN_OUT")}
                </MenuItem>
              </Select>
            </div>
          ) : (
            <div
              className="loginBtn"
              onClick={() => setOpen(true)}
              style={{ cursor: "pointer" }}
            >
              <span className="span_login">{t("LOGIN_BTN")}</span>
            </div>
          )}
        </div>
        {isMobile && location.pathname !== "/connected" && (
          <div style={{ marginTop: "5px" }} onClick={backArrow}>
            <img
              style={{
                width: "26px",
                position: "relative",
                top: "14px",
                left: "-9px",
              }}
              src={Arrow}
              alt="Arrow"
              className="Arrow_cancel"
            />
          </div>
        )}

        {open ? <Login open={open} setOpen={setOpen} /> : null}
        {showDetailsBusinessInLogoConnect || moveToDetailsOfBusiness ? <DetailsOfBusiness /> : null}

        {showLogo() && (
          <div
            className="logoPogo"
            style={{
              marginTop: isMobile ? "10px" : "0",
              width: isMobile && "98px",
              height: isMobile && "26px",
            }}
          >
            <img className="logo_position" src={pogo} alt="pogo" />
            <div style={{ position: "relative" }}>
              <a className="linkHomePage" href="#">
                {t("HEADER_LOGO")}
              </a>
            </div>
          </div>
        )}
      </div>
  </div>
  );
}
