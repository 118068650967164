import * as React from "react";
import "../detailsPackage/MultiSteps/stepsNum/stepsNumCss/popUp.css";
import { TextField, CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Button from "@mui/material/Button";
import { useMediaQuery } from "react-responsive";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
import parsePhoneNumber from "libphonenumber-js";
import Arrow3 from "../images/Arrow 3.png";
import "./login.css";
import DetailsOfBusiness from "../detailsOfBusiness/details_business";

import trash from "../images/Trash.png";
import { t } from "i18next";
// import DetailsOfPackage from '../../detailsOfPackage';
import { type } from "@testing-library/user-event/dist/type";
import { SettingsPowerRounded } from "@mui/icons-material";
import { DataContext } from "../../../dataContext";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

export default function Login({ open, setOpen, close, click }) {
  const {
    showDetailsBusiness,
    setShowDetailsBusiness,
    setValidToken,
    errorsPhoneName,
    setErrorsPhoneName,
    validToken,
    phone_connect,
    setPhone_connect,
    moveToDetailsOfBusiness,
    setMoveToDetailsOfBusiness,
    setOpenLoginPopup
  } = useContext(DataContext);

  const [error, setError] = useState(null);
  const [CodeSmsPopUp, setCodeSmsPopUp] = useState(null);
  const [code, setCode] = useState("");
  const [checkValidCode, setCheckValidCode] = useState("");
  // const [phone_connect, setPhone_connect] = useState({
  //   phoneNumber: "",
  // });

  const navigate = useNavigate();
  const [phoneToServer, setPhoneToServer] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });

  const focusOutPhone = async ({ target: { value } }) => {
    let valid = true;
    const newPhoneNumber = parsePhoneNumber(value, "IL");
    if (newPhoneNumber) {
      valid = newPhoneNumber.isValid();
      // if (valid)
    } else {
      //  setErrorPhone(true)
    }
  };

  const onChange = ({ target: { value } }) => {
    let valid = true;
    const newPhoneNumber = parsePhoneNumber(value, "IL");
    if (newPhoneNumber) {
      valid = newPhoneNumber.isValid();
      if (valid) {
        value = newPhoneNumber.number;
      }
    }

    phone_connect.phoneNumber = value;
    setPhone_connect({
      ...phone_connect,
    });

    const phone = "phone";
    const bool = valid ? false : true;

    setErrorsPhoneName((prevState) => ({
      ...prevState,
      [phone]: bool,
    }));
  };

  const checkPhoneNumber = () => {
    let valid = true;
    if (!phone_connect.phoneNumber) return false;
    const newPhoneNumber = parsePhoneNumber(phone_connect.phoneNumber, "IL");
    valid = newPhoneNumber?.isValid();
    if (valid) {
      // setCodeSmsPopUp(true)
      return false;
    } else {
      return true;
      const phone = "phone";
      const bool = !valid;
      setErrorsPhoneName((prevState) => ({
        ...prevState,
        [phone]: bool,
      }));
    }
  };

  let components;
  let str_phone;
  let v;
  if (phone_connect.phoneNumber.length > 7) {
    const phoneNumber = phone_connect.phoneNumber;
    const countryCodeLength = phoneNumber.length - 9;

    components = {
      IDDCC: phoneNumber.substr(0, countryCodeLength),
      NN: phoneNumber.substr(countryCodeLength, phoneNumber.length),
    };

    // str_phone = '0' + components.NN;
    // // str_phone = components.NN.replace(/^\d{3}-\d{8}$/);
    // v = components.NN.replace(/(\d{1})(\d{2})/, "$1-$2");

    // console.log(v);
  }

  useEffect(() => {
    const relaseDriver = async () => {
      try {
        const data = { phone: phone_connect.phoneNumber };
        const result = await axios.post(
          "/pogo_connect_api/server/web/index.php/customers/validate_phone",
          data,
          {
            headers: {
              // 'Authorization': 'Bearer PpijE4lGk-x3RyECiyeak00UK4fZYtDT_1670340550',
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    };
    CodeSmsPopUp && relaseDriver();
  }, [CodeSmsPopUp]);

  useEffect(() => {
    const checkCode = async () => {
      try {
        const data = { phone: phone_connect.phoneNumber, code: code * 1 };
        const result = await axios.post(
          "/pogo_connect_api/server/web/index.php/customers/business_login",
          data,
          {
            headers: {
              // 'Authorization': 'Bearer PpijE4lGk-x3RyECiyeak00UK4fZYtDT_1670340550',
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );

        console.log(result);
        // return
        if (result.data.access_token) {
          setValidToken({
            access_token: result.data.access_token,
            customer_name: result.data.customer_name,
          });
          localStorage.setItem(
            "access_token_client",
            JSON.stringify({
              access_token: result.data.access_token,
              customer_name: result.data.customer_name,
              customer_phone: phone_connect.phoneNumber,
            })
          );
        }
        //navigate("/connected", { replace: true });
        //navigate("/", { replace: true });
        //window.location.reload();
        localStorage.setItem(
          "access_customer_phone",
          JSON.stringify({
            customer_phone: phone_connect.phoneNumber,
          })
        );
        // if (result.data.message !== "No customer") {
        // else {
        //   localStorage.removeItem("access_token_client");
        //   setValidToken(false);
        //   navigate("/");
        // }
        setOpenLoginPopup(false);
        setMoveToDetailsOfBusiness(true);
      } catch (error) {
        if (error.response?.status == 400) {
          setError(true);
          setCheckValidCode(false);
        }
        console.log(error);
      }
    };
    checkValidCode && checkCode();
  }, [checkValidCode]);

  // https://api.getpogo.app/

  // const checkCodeSms = async () => {
  //     const result = await verificationCheck({ nameAndPhone, value })
  //     if (result == 'OK') {
  //         //    console.log(result);
  //         setOpen(false)

  //         setResponseSmsValid('phone verified')

  //     } else {
  //         return setError(true);
  //     }

  // }

  // const backToPhoneAndName = () => {
  //     switchEditBtnSummary('fullNameAndPhone')
  //     setResponseSmsValid(false)

  // }

  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });
  // const labelText = `${labelDestination}(${AllDetailsOfPackage.length})`
  const InputLabelProps = {
    display: "flex",
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "25px",
    textAlign: "right",
    color: "#0D152E",
    direction: ltrTheme.direction === "rtl" && "ltr",
  };
  const isRTL = (s) => {
    if (s !== "") {
      var ltrChars =
          "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
          "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
        rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
        rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]");

      return rtlDirCheck.test(s);
    }
    return true;
  };

  // const deletePackageSingel = () => {
  //     selected(AllDetailsOfPackage.filter(item => item !== detailsOfPackageSingel))
  //     close(detailsOfPackageSingel)
  // }

  return !CodeSmsPopUp ? (
    <div
    // style={{ maxWidth: "675px",height:"490px", margin: "0 auto" }}
    >
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "40px 4px 50px",
            gap: "10px",
            width: "675px",
            height: "343px",
            overflowX: "hidden",
            //    boxShadow:"none",
            //     color:"white",
            transition: "none",
            background: "#FFFFFF",
            borderRadius: "4px",
          },
          "& .MuiDialogContent-root": {
            width: !isMobile ? "90%" : "100%",
            overflow: "hidden",
          },
        }}
        open={open}
        onClose={close}
      >
        <CssBaseline />
        <DialogContent>
          <div
            className="mobile_width_popUp padding_margin"
            style={{ display: "block" }}
          >
            {/* {isMobile && <div  style={{display:"block"}} className='cancel_mobile'><img className='Arrow_cancel' src={Arrow3} onClick={() => close()} /></div>} */}
            <CssBaseline />

            <div
              className="divPopUp"
              style={{
                maxWidth: isTabletOrMobile ? "445px" : "545px",
                height: "174px",
                display: "block",
              }}
            >
              <div className="moreDetailsHead">
                {" "}
                <h2
                  style={{
                    width: isMobile && "100%",
                    textAlign: isMobile && "right",
                  }}
                  className="h2HeaderMoreDetails"
                >
                  {t("LOGIN_CONNECT")}
                </h2>
              </div>
              <div style={{ width: isMobile && "100%" }}>
                <div
                  className="margin mobile_flex"
                  style={{ justifyContent: "flex-end" }}
                >
                  <CacheProvider
                    value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
                  >
                    <TextField
                      autoFocus
                      error={!!errorsPhoneName.phone}
                      helperText={
                        errorsPhoneName.phone == true &&
                        t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME_VALIDPHON")
                      }
                      value={phone_connect.phoneNumber}
                      onFocus={() => setErrorsPhoneName(false)}
                      onBlur={focusOutPhone}
                      style={{ width: isTabletOrMobile && "100%" }}
                      onChange={onChange}
                      autoComplete="off"
                      label={t("LOGIN_CONNECT_PHONE_NUM")}
                      sx={{
                        width: "100%",
                        height: "81px",
                        "& .MuiOutlinedInput-root": {
                          fontFamily: "Heebo",
                          fontSize: "16px",
                          "& fieldset": {
                            direction:
                              ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                          },
                        },
                      }}
                      id="outlined-size-small"
                      inputProps={{
                        style: {
                          direction:
                            ltrTheme.direction === "ltr" && isRTL()
                              ? "rtl"
                              : "ltr",
                        },
                      }}
                      InputProps={{ style: InputLabelProps }}
                      InputLabelProps={{
                        style: InputLabelProps,
                        shrink: true,
                      }}
                    />
                  </CacheProvider>
                </div>
              </div>
            </div>

            <div className="widthActions" style={{ maxWidth: "545px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile && "column",
                  alignItems: isMobile && "center",
                  justifyContent: isMobile ? "center" : "space-between",
                  marginBottom: "20px",
                }}
              >
                <Button
                  disabled={checkPhoneNumber()}
                  style={{
                    textTransform: "none",
                    background: "#583DFF",
                    width: isMobile && "100%",
                    margin: isMobile && "0px",
                  }}
                  variant="contained"
                  className="saveMoreDetails"
                  onClick={(e) => {
                    setCodeSmsPopUp(true);
                  }}
                >
                  {t("ADD_DETAILS_WINDOW_BTN_CONFIRM")}
                </Button>
                {!isMobile && (
                  <Button
                    style={{ textTransform: "none" }}
                    variant="outlined"
                    className="cancelMoreDetails"
                    onClick={() => setOpen(false)}
                  >
                    {t("ADD_DETAILS_WINDOW_BTN_CANCEL")}
                  </Button>
                )}
                {isMobile && (
                  <div style={{ marginTop: "36px", marginBottom: "33px" }}>
                    <img
                      className="btn imgMoreDetailsHidden"
                      style={{
                        visibility: "visible",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpen(false);
                      }}
                      src={trash}
                      alt="trash"
                    />
                    <span className="imgMoreDetailsShow">
                      {t("DELETE_STATION_MOBILE")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  ) : !moveToDetailsOfBusiness ? (
    <Dialog
      sx={{
        "& .MuiDialogContent-root": { overflow: isMobile && "hidden" },
        "& .MuiPaper-root": {
          transition: "none",
        },
      }}
      open={true}
    >
      <CacheProvider value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}>
        <CssBaseline />
        <DialogContent>
          <div
            className="divPopUp phonePopUp"
            style={{ gap: isMobile && "10px" }}
          >
            <div className="moreDetailsHead phone_head_div">
              {" "}
              <h2 className="h2HeaderMoreDetails phoneHeader2">
                {t("STEPS_NUM_4_VALIDATION_HEADER_LINE_1")}
              </h2>
            </div>
            <div className="marg_popUp_mobile">
              <div className="sendSmsHeader sendSmsHeader_mobile">
                <p className="head_p phone_head_p_mobile">
                  {t("STEPS_NUM_4_SENT_PHONE")}
                </p>
                <span className="phoneNumHead phoneNumHead_mobile">{`0${components.NN}`}</span>
                <div className="receiveCode receiveCode_mobile">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCodeSmsPopUp(true);
                    }}
                    className="sendAgain sendAgain_mobile"
                  >
                    {t("STEPS_NUM_4_DIDN'T_RECEIVE")}
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCodeSmsPopUp(false);
                    }}
                    className="sendAgain sendAgain_mobile"
                  >
                    {t("STEPS_NUM_4_ISN'T_MYNUM")}
                  </a>
                </div>
              </div>
            </div>

            <TextField
              error={!!error}
              value={code}
              autoFocus
              onChange={(e) => {
                setCode(e.target.value);
              }}
              //    ref={ref}
              style={{ margin: "25px auto 25px", width: "90%" }}
              label={t("STEPS_NUM_4_WHICH_CODE_RECEIVE")}
              // id="outlined-size-small"
              autoComplete="off"
              helperText={error && "קוד שגוי"}
              sx={{
                width: 450,
                "& .MuiOutlinedInput-root": {
                  fontSize: "16px",
                  "& fieldset": {
                    direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                  },
                },
              }}
              onFocus={() => setError(false)}
              InputLabelProps={{
                style: InputLabelProps,

                shrink: true,
              }}
            />

            <DialogActions className="continue">
              <Button
                onClick={(e) => {
                  setCheckValidCode(true);
                }}
                variant="outlined"
                style={{
                  textTransform: "none",
                  padding: "13px 15px",
                  color: "#FFFFFF",
                  textAlign: "center",
                  width: "450px",
                }}
              >
                {t("DETAILS_OF_PACKAGE_CONTINUE")}
              </Button>
            </DialogActions>
          </div>
        </DialogContent>
      </CacheProvider>
    </Dialog>
  ) : (
    <DetailsOfBusiness />
  );
}
