import {
  CardElement,
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import "../payment/payment.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import { useMediaQuery } from "react-responsive";
import createCache from "@emotion/cache";
import axios from "axios";
import { Button, FormGroup, TextField, CssBaseline } from "@mui/material";
import { t } from "i18next";
import { useContext } from "react";
import React, { useRef, useState, useImperativeHandle } from "react";
import { DataContext } from "../../../../dataContext";
import { useEffect } from "react";
import StripeInput from "./refCardStripe";
// import paymentSumit from './paymentSumit';
import $ from 'jquery';

export default function PaymentForm({
  setErrorBusinnesDetails
}) {
  const [success, setSuccess] = useState(false);
  const [showCardValue, setShowCardValue] = useState(true);
  const ref = useRef();
  // const stripe = useStripe();
  // const elements = useElements();
  const inputRef = useRef();
  const {
    errorsCreditCard,
    setErrorsCreditCard,
    update_credit_card,
    setUpdate_credit_card,
    validToken,
    allDetailsofBusinnes,
    setAllDetailsofBusinnes,
    client_secret,
    sumitCusId,
    setClientSecret,
    idCreditCard,
    setIdCreditCard,
    paymentMethodId,
    setPaymentMethodId,
  } = useContext(DataContext);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });

  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  // console.log(allDetailsofBusinnes);

  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });
  const InputLabelProps = {
    display: "flex",
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "25px",
    textAlign: "right",
    color: "#0D152E",
    direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
  };
  var sumit_customer_id = null;
  // useEffect(() => {
  //   const handleSubmit = async () => {
  //     // e.preventDefault()
  //     // console.log(e);
  //     // event.preventDefault();
  //     if (!stripe || !elements) {
  //       // Stripe.js has not yet loaded.
  //       // Make sure to disable form submission until Stripe.js has loaded.
  //       // console.log('j');
  //       return;
  //     }
  //     const result = await stripe.confirmCardSetup(client_secret, {
  //       payment_method: {
  //         card: elements.getElement(CardNumberElement),
  //         card: elements.getElement(CardExpiryElement),
  //         card: elements.getElement(CardCvcElement),
  //         billing_details: {
  //           name: allDetailsofBusinnes.fullNameCardOwner
  //             ? allDetailsofBusinnes.fullNameCardOwner
  //             : "",
  //         },
  //       },
  //     });

  //     if (result.error) {
  //       setErrorBusinnesDetails(true);
  //     } else {
  //       console.log(result);
  //       setPaymentMethodId(result.setupIntent.payment_method);
  //     }
  //   };
  //   client_secret && handleSubmit();
  // }, [client_secret]);
  // console.log(client_secret);

  useEffect(() => {
    console.log({
      client_secret: sumitCusId,
    });
  }, [sumitCusId]);
  
  const handleOnSubmit = async (e) => {
    const data = {
      "Credentials": {
        "CompanyID": 52536134,
        "APIKey": "QgmPSe3W4m6J9ZLMMQXpiE4RfAqAEgQ07AHHa5v74O9WTeBSwf"
      },
      "Customer": {
        "ID": sumitCusId,
        "Folder": "POGO",
        "Properties": {
          "additionalProp1": "1",
          "additionalProp2": "2",
          "additionalProp3": "3"
        },
        "Name": allDetailsofBusinnes.fullName ? allDetailsofBusinnes.fullName : "",
        "Phone": allDetailsofBusinnes.phone ? allDetailsofBusinnes.phone : "",
        "EmailAddress": allDetailsofBusinnes.mail ? allDetailsofBusinnes.mail : "",
        "City": "BneyBrak",
        "Address": allDetailsofBusinnes.addressCollection,
        "ZipCode": "5152314",
        "CompanyNumber": "211550231",
        "NoVAT": false,
        "SearchMode": "None (1)"
      },
      "SingleUseToken": e.Data.SingleUseToken
    };
    const result = await axios.post("https://api.sumit.co.il/billing/paymentmethods/setforcustomer/", data);
console.log(result);
    if (result.data.UserErrorMessage) {
      setErrorBusinnesDetails(true);
    } else {
      console.log(result);
      setPaymentMethodId(result.Data.PaymentMethod.ID);
    }
  };
  // useEffect(() => {

  //   $(async function() {
  //     OfficeGuy.Payments.BindFormSubmit({
  //         CompanyID: '52536134',
  //         APIPublicKey: 'jdRfmo0wG4j9TBbdM56QfgVKaEwM7mcn1ktS8P7PkuXmfvyRkO',
  //         ResponseCallback: await handleOnSubmit
  //     });
  // });
  // }, []);
  const handleChangefullNameCardOwner = (e) => {
    console.log(sumitCusId);
    allDetailsofBusinnes.fullNameCardOwner = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
  };
  const handleChangeId = (e) => {
    allDetailsofBusinnes.id = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
  };
  const switchShowInputComponent = (element) => {
    if (!validToken) {
      return element;
    }
    // if (validToken && showCardValue) {
    //     return
    // }
    if (validToken && !showCardValue) {
      return element;
    }
  };
  const handelOnChangeCardNumber = (e) => {
    sumit_customer_id = sumitCusId;
    allDetailsofBusinnes.cardNumber = e.target.value;
    // if(e.target.value) {
    //     errorsCreditCard.empty = true
    //     setErrorsCreditCard({...errorsCreditCard})
    // }

    // console.log(e);
    //console.log(e);
    e.error
      ? (errorsCreditCard.error = true)
      : (errorsCreditCard.error = false);
    e.empty
      ? (errorsCreditCard.empty = true)
      : (errorsCreditCard.empty = false);
    setErrorsCreditCard({ ...errorsCreditCard });
    // if(e.complet){
    setUpdate_credit_card(true);
    // }
    setShowCardValue(false);
  };

  const handelOnChangeExpiryDate = (e) => {
    allDetailsofBusinnes.expiryDate = e.target.value;
    e.error
      ? (errorsCreditCard.error = true)
      : (errorsCreditCard.error = false);
    e.empty
      ? (errorsCreditCard.empty = true)
      : (errorsCreditCard.empty = false);
    setErrorsCreditCard({ ...errorsCreditCard });

    setShowCardValue(false);
  };
  const handelOnChangeCvc = (e) => {
    allDetailsofBusinnes.cvc = e.target.value;
    e.error
      ? (errorsCreditCard.error = true)
      : (errorsCreditCard.error = false);
    e.empty && !validToken
      ? (errorsCreditCard.empty = true)
      : (errorsCreditCard.empty = false);
    setErrorsCreditCard({ ...errorsCreditCard });

    setShowCardValue(false);
  };

  return (
<form onSubmit={handleOnSubmit} data-og="form" className="mobile_width_popUp"
      style={{ width: "100%", height: "auto", margin: "0 auto", display: "block" }}>
      <div className="og-errors"></div>
      <div style={{ width: "100%" }} className="moreDetailsHead">
            {" "}
            <h2
              style={{ textAlign: isMobile && "right", width: "100%" }}
              className="h2HeaderMoreDetails"
            >
              {t("PAYMENT_DETAILS")}
            </h2>
        </div>
        <div
            style={{
              marginBottom: !isMobile && "25px",
              height: isMobile && "96px",
            }}
          >
            <CacheProvider
              value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
            >
              <TextField
                placeholder={t("CREDIT_NAME")}
                autoComplete="off"
                value={allDetailsofBusinnes.fullNameCardOwner
                  ? allDetailsofBusinnes.fullNameCardOwner
                  : ""}
                onChange={handleChangefullNameCardOwner}
                label={t("CREDIT_NAME")}
                id="outlined-size-small"
                sx={{
                  width: "100%",
                  height: !isMobile ? "81px" : "96px",
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Heebo",
                    fontSize: "16px",
                    height: "71px",
                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    height: "71px",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "17px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                    direction: ltrTheme.direction === "ltr" ? "rtl" : "ltr",
                  },
                }}
                InputLabelProps={{
                  style: {
                    margin: "auto",
                    display: "flex",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                  },

                  shrink: true,
                }} />
            </CacheProvider>
          </div>
        <div>
        <label>
          <input
            type="hidden"
            size="20"
            maxLength="20"
            name="cardNumber"
            data-og="cardnumber"
            value={
              allDetailsofBusinnes.cardNumber
                ? allDetailsofBusinnes.cardNumber
                : ""
            }
          />
        </label>
        <div
        style={{
          marginBottom: !isMobile && "35px",
          height: isMobile && "96px",
        }}
      >
        <CacheProvider
          value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
        >
          <TextField
          
            placeholder={t("CREDIT_NUM")}
            label={t("CREDIT_NUM")}
            autoComplete="off"
            value={
              allDetailsofBusinnes.cardNumber
                ? allDetailsofBusinnes.cardNumber
                : ""
            }
            onChange={handelOnChangeCardNumber}
            sx={{
              maxWidth: "545px",
              width: "100%",
              "& .MuiOutlinedInput-root": {
                fontFamily: "Heebo",
                fontSize: "16px",
                height: "71px",
                "& fieldset": {
                  direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                },
              },
              "& .MuiOutlinedInput-input": {
                direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                textAlign: "left",
              },
            }}
            // inputProps={getCardNumberProps({ onChange: handleChangeCardNumber })}
            //  autoFocus
            InputProps={{
              // startAdornment: <InputAdornment position="start">₪</InputAdornment>,
              style: {
                height: "71px",
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "17px",
                lineHeight: "25px",
                textAlign: "right",
                color: "#0D152E",
              },
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                margin: "auto",
                display: "flex",
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "25px",
                textAlign: "right",
                color: "#0D152E",
              },
            }}

            // inputRef={getCardNumberProps().ref}
          />
        </CacheProvider>
      </div>
      </div>


        <input
          type="hidden"
          size="2"
          maxLength="2"
          name="expirationMonth"
          value={
            allDetailsofBusinnes.expiryDate
              ? allDetailsofBusinnes.expiryDate.slice(0,2)
              : ""
          }
          data-og="expirationmonth"
        />

        <input
          type="hidden"
          size="4"
          maxLength="4"
          name="expirationYear"
          value={
            allDetailsofBusinnes.expiryDate
              ? allDetailsofBusinnes.expiryDate.slice(2,4)
              : ""
          }
          data-og="expirationyear"
        />
        <div
        className="margin mobile_flex"
        style={{ marginBottom: !isMobile && "25px" }}
      >
        <CacheProvider
          value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
        >
          <TextField
            placeholder={t("EXPIRY_CREDIT")}
            label={t("EXPIRY_CREDIT")}
            maxLength="4"
            // inputProps={getExpiryDateProps({ onChange: handleChangeExpiryDate })}
            value={
              allDetailsofBusinnes.expiryDate
                ? allDetailsofBusinnes.expiryDate
                : ""
            }
            onChange={handelOnChangeExpiryDate}
            //  style={{marginBottom:isMobile && "25px"}}
            id="outlined-size-small"
            sx={{
              ml: isMobile ? 0 : 3,
              width: "48%",
              height: !isMobile ? "81px" : "96px",
              "& .MuiOutlinedInput-root": {
                fontFamily: "Heebo",
                fontSize: "16px",
                height: "71px",
                marginBottom: isMobile && "25px",

                "& fieldset": {
                  height: isMobile ? "71px" : "75.99px",
                  direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                },
              },
              "& .MuiOutlinedInput-input": {
                direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                textAlign: "left",
              },
            }}
            InputProps={{             
              style: {
                height: "71px",
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "17px",
                lineHeight: "25px",
                textAlign: "right",
                color: "#0D152E",
                flexDirection: "row-reverse",
                direction: ltrTheme.direction === "rtl" && "ltr",
              },
            }}
            InputLabelProps={{
              style: InputLabelProps,
              shrink: true,
            }}
          />
        </CacheProvider>

        <label>
          <input
            type="hidden"
            size="4"
            maxLength="4"
            name="cvv"
            value={allDetailsofBusinnes.cvc ? allDetailsofBusinnes.cvc : ""}
            data-og="cvv"
          />
        </label>
        <CacheProvider
          value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
        >
          <TextField
            placeholder={t("3_NUM_CVC")}
            // error={!!error}
            // ref={refInputElement3}
            // helperText={error && t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME_VALIDPHON")}
            label={t("3_NUM_CVC")}
            // inputProps={getCVCProps({ onChange: handleChangeCvc })}
            value={allDetailsofBusinnes.cvc ? allDetailsofBusinnes.cvc : ""}
            onChange={handelOnChangeCvc}
            // autoComplete='off'

            sx={{
              width: "48%",
              height: !isMobile ? "81px" : "96px",
              "& .MuiOutlinedInput-root": {
                height: "71px",
                fontFamily: "Heebo",
                fontSize: "16px",

                "& fieldset": {
                  direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                },
              },
              "& .MuiOutlinedInput-input": {
                direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                textAlign: "left",
              },
            }}
            id="outlined-size-small"
            // inputProps={
            //     { style: { direction: ltrTheme.direction === "ltr" && isRTL("") ? "rtl" : "ltr" } }}
            InputProps={{
              style: {
                height: "71px",
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "17px",
                lineHeight: "25px",
                textAlign: "right",
                color: "#0D152E",
                direction: ltrTheme.direction === "ltr" ? "rtl" : "ltr",
              },
            }}
            InputLabelProps={{
              style: InputLabelProps,
              shrink: true,
            }}
          />
        </CacheProvider>
      </div>
      <div>
        <label>
          <input
            type="hidden"
            name="citizenId"
            value={allDetailsofBusinnes.id ? allDetailsofBusinnes.id : ""}
            data-og="citizenid"
          />
        </label>
        <div style={{ marginBottom: "45px" }}>
            <CacheProvider
              value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
            >
              <TextField
                placeholder={t("ID")}
                autoComplete="off"
                onChange={handleChangeId}
                value={allDetailsofBusinnes.id ? allDetailsofBusinnes.id : ""}
                label={t("ID")}
                id="outlined-size-small"
                sx={{
                  width: "100%",
                  height: "81px",
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Heebo",
                    fontSize: "16px",
                    height: "71px",
                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                inputProps={{
                  style: {
                    height: "71px",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "17px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                    direction: ltrTheme.direction === "ltr" ? "rtl" : "ltr",
                  },
                }}
                InputLabelProps={{
                  style: {
                    margin: "auto",
                    display: "flex",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                  },

                  shrink: true,
                }} />
            </CacheProvider>
          </div>
      </div>
      <input type="hidden" name="og-token" id="og-token" />
      <input type="submit" value={t("SAVE_PAYMENT_METHOD")} />
    </form>
    // <div>
      // <div
      //   style={{
      //     marginBottom: !isMobile && "35px",
      //     height: isMobile && "96px",
      //   }}
      // >
      //   <CacheProvider
      //     value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
      //   >
      //     <TextField
          
      //       placeholder={t("CREDIT_NUM")}
      //       label={t("CREDIT_NUM")}
      //       ref={ref}
      //       value={
      //         allDetailsofBusinnes.cardNumber
      //           ? allDetailsofBusinnes.cardNumber
      //           : ""
      //       }
      //       onChange={(e) => handelOnChangeCardNumber(e)}
      //       sx={{
      //         maxWidth: "545px",
      //         width: "100%",
      //         "& .MuiOutlinedInput-root": {
      //           fontFamily: "Heebo",
      //           fontSize: "16px",
      //           height: "71px",
      //           "& fieldset": {
      //             direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
      //           },
      //         },
      //         "& .MuiOutlinedInput-input": {
      //           direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
      //           textAlign: "left",
      //         },
      //       }}
      //       // inputProps={getCardNumberProps({ onChange: handleChangeCardNumber })}
      //       //  autoFocus
      //       InputProps={{
      //         inputComponent: switchShowInputComponent(StripeInput),
      //         inputProps: {
      //           component: switchShowInputComponent(CardNumberElement),
      //         },
      //         // startAdornment: <InputAdornment position="start">₪</InputAdornment>,
      //         style: {
      //           height: "71px",
      //           fontFamily: "Heebo",
      //           fontStyle: "normal",
      //           fontWeight: "500",
      //           fontSize: "17px",
      //           lineHeight: "25px",
      //           textAlign: "right",
      //           color: "#0D152E",
      //         },
      //       }}
      //       InputLabelProps={{
      //         shrink: true,

      //         style: {
      //           margin: "auto",
      //           display: "flex",
      //           fontFamily: "Heebo",
      //           fontStyle: "normal",
      //           fontWeight: "500",
      //           fontSize: "16px",
      //           lineHeight: "25px",
      //           textAlign: "right",
      //           color: "#0D152E",
      //         },
      //       }}

      //       // inputRef={getCardNumberProps().ref}
      //     />
      //   </CacheProvider>
      // </div>

      // <div
      //   className="margin mobile_flex"
      //   style={{ marginBottom: !isMobile && "25px" }}
      // >
      //   <CacheProvider
      //     value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
      //   >
      //     <TextField
      //       placeholder={t("EXPIRY_CREDIT")}
      //       label={t("EXPIRY_CREDIT")}
      //       // inputProps={getExpiryDateProps({ onChange: handleChangeExpiryDate })}
      //       value={
      //         allDetailsofBusinnes.expiryDate
      //           ? allDetailsofBusinnes.expiryDate
      //           : ""
      //       }
      //       onChange={(e) => handelOnChangeExpiryDate(e)}
      //       //  style={{marginBottom:isMobile && "25px"}}
      //       id="outlined-size-small"
      //       sx={{
      //         ml: isMobile ? 0 : 3,
      //         width: "48%",
      //         height: !isMobile ? "81px" : "96px",
      //         "& .MuiOutlinedInput-root": {
      //           fontFamily: "Heebo",
      //           fontSize: "16px",
      //           height: "71px",
      //           marginBottom: isMobile && "25px",

      //           "& fieldset": {
      //             height: isMobile ? "71px" : "75.99px",
      //             direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
      //           },
      //         },
      //         "& .MuiOutlinedInput-input": {
      //           direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
      //           textAlign: "left",
      //         },
      //       }}
      //       InputProps={{
      //         inputComponent: switchShowInputComponent(StripeInput),
      //         inputProps: {
      //           component: switchShowInputComponent(CardExpiryElement),
      //         },
      //         // startAdornment: <InputAdornment position="start">₪</InputAdornment>,
      //         style: {
      //           height: "71px",
      //           fontFamily: "Heebo",
      //           fontStyle: "normal",
      //           fontWeight: "500",
      //           fontSize: "17px",
      //           lineHeight: "25px",
      //           textAlign: "right",
      //           color: "#0D152E",
      //           flexDirection: "row-reverse",
      //           direction: ltrTheme.direction === "rtl" && "ltr",
      //         },
      //       }}
      //       InputLabelProps={{
      //         style: InputLabelProps,
      //         shrink: true,
      //       }}
      //     />
      //   </CacheProvider>
        // <CacheProvider
        //   value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
        // >
        //   <TextField
        //     placeholder={t("3_NUM_CVC")}
        //     // error={!!error}
        //     // ref={refInputElement3}
        //     // helperText={error && t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME_VALIDPHON")}
        //     label={t("3_NUM_CVC")}
        //     // inputProps={getCVCProps({ onChange: handleChangeCvc })}
        //     value={allDetailsofBusinnes.cvc ? allDetailsofBusinnes : ""}
        //     onChange={(e) => handelOnChangeCvc(e)}
        //     // autoComplete='off'

        //     sx={{
        //       width: "48%",
        //       height: !isMobile ? "81px" : "96px",
        //       "& .MuiOutlinedInput-root": {
        //         height: "71px",
        //         fontFamily: "Heebo",
        //         fontSize: "16px",

        //         "& fieldset": {
        //           direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
        //         },
        //       },
        //       "& .MuiOutlinedInput-input": {
        //         direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
        //         textAlign: "left",
        //       },
        //     }}
        //     id="outlined-size-small"
        //     // inputProps={
        //     //     { style: { direction: ltrTheme.direction === "ltr" && isRTL("") ? "rtl" : "ltr" } }}
        //     InputProps={{
        //       inputComponent: StripeInput,
        //       inputProps: {
        //         component: CardCvcElement,
        //       },
        //       style: {
        //         height: "71px",
        //         fontFamily: "Heebo",
        //         fontStyle: "normal",
        //         fontWeight: "500",
        //         fontSize: "17px",
        //         lineHeight: "25px",
        //         textAlign: "right",
        //         color: "#0D152E",
        //         direction: ltrTheme.direction === "ltr" ? "rtl" : "ltr",
        //       },
        //     }}
        //     InputLabelProps={{
        //       style: InputLabelProps,
        //       shrink: true,
        //     }}
        //   />
        // </CacheProvider>
    //   </div>
    // </div>
  );
}
