import { useTranslation } from "react-i18next";
import "./stepsNum/stepsNumCss/checkTypePackage&postData.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";
import packageImg from "../../images/Cardboard Box.png";
import envelope from "../../images/Envelope.png";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import { Collapse } from "@mui/material";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import _ from "lodash";
import { useState, useEffect, useContext } from "react";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import RadioButtonUncheckedSharpIcon from "@mui/icons-material/RadioButtonUncheckedSharp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { updateServer } from "./stepsNum/updateServer";
import { DataContext } from "../../../../dataContext";
import { logDOM } from "@testing-library/react";
// import { display, style } from "@mui/system";
import { TextField, CssBaseline } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
//const url = `proj/pogo_connect/lw_pogo_connect/basic/server/web/tracks/post_new_track`;
const url = `pogo_connect_api/server/web/tracks/post_new_track`;

export default function SetDataToServer({ switchEditBtnSummary }) {
  const {
    type,
    setType,
    typeShippment,
    setTypeShippment,
    getDataFromServer,
    setGetDataFromServer,
    checkboxValue,
    setCheckboxValue,
    exitAddress,
    time,
    detailsOfPackage,
    setVal,
    nameAndPhone,
    setIsSelectShipment,
    setIsSelectVehicle,
    errorCarType,
    setErrorCarType
  } = useContext(DataContext);

  const { token } = useParams();
  const [response, setResponse] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });

  // old: Before changing a date to a selection list
  //const arrayPickUpTime = time?.pickUpTime?.split("T");
  //const arrayDdLine = time?.ddLine?.split("T");

  //const start_time = [arrayPickUpTime[0], arrayPickUpTime[1]]?.join(" ");
  //const end_time = [arrayDdLine[0], arrayDdLine[1]]?.join(" ");

  const start_time = time?.pickUpTime ? time?.pickUpTime : "1";
  const end_time = time?.ddLine ? time.ddLine : "1";
  const [textArea, setTextArea] = useState("");
  const [isShownM, setIsShownM] = useState(false);
  const [isShownC, setIsShownC] = useState(true);
  const [isShownT, setIsShownT] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);

  const { t, i18n } = useTranslation();
  const ordersAddress = detailsOfPackage.map((order, i) => {
    return {
      address: {
        lat: order.lat.toString(),
        lng: order.lng.toString(),
        cityName: order.cityName,
        streetName: order.streetName,
        streetNum: order.streetNum,
      },
      order_price: order.payForStation,
      note: order.comment,
      contactName: order.contactName,
      contactPhone: order.contactPhone,
      interactionType: order.pickOrDrop,
    };
  });

  const [details, setDetails] = useState(
    t("STEPS_NUM_2_TYPE_PACKAGE_SELECT_2_DETAIL")
  );

  const data = {
    exit_address: {
      cityName: exitAddress?.cityName || "",
      streetName: exitAddress?.streetName || "",
      streetNum: exitAddress?.streetNum || "",
    },
    start_time: start_time,
    end_time: end_time,
    orders: ordersAddress,
    price: getDataFromServer.new_price ? getDataFromServer.new_price : "",
    currency_symbol: "₪",
    customer_name: nameAndPhone?.fullName,
    phone: nameAndPhone?.phoneNumber,
    erea_code: nameAndPhone?.erea_code,
    driver_app: "pogo connect",
    track_id: getDataFromServer?.track_id ? getDataFromServer.track_id : "",
  };

  useEffect(() => {
    // בדיקה אם נעשתה כבר פניה פעם אחת לשרת וצריך לעדכן נתונים
    //switchUpdateOrNew();
  }, []);

  const fetchData = async () => {
    // getDataFromServer.isLoading = "start";
    // getDataFromServer.isLoading = false;
    // setGetDataFromServer({ ...getDataFromServer });
    // old
    // getDataFromServer.isLoading = "start";
    // setGetDataFromServer({ ...getDataFromServer });
    // try {
    //   const result = await axios.post(url, data, {
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       "Content-Type": "application/json",
    //     },
    //   });
    //   if (result.status !== 200) {
    //     getDataFromServer.isLoading = "error";
    //     setGetDataFromServer({ ...getDataFromServer });
    //   } else {
    //     setVal(Math.round(result.data.price_min));
    //     const getDataFromServer = {
    //       track_id: result.data.track_id,
    //       new_price: null,
    //       price_min: Math.round(result.data.price_min * 1),
    //       track_minutes: result.data.track_minutes,
    //       track_distance: result.data.track_distance,
    //       count_orders: result.data.count_orders,
    //       currency_symbol: result.data.currency_symbol,
    //       isLoading: false,
    //     };
    //     setGetDataFromServer({ ...getDataFromServer });
    //   }
    // } catch (error) {
    //   getDataFromServer.isLoading = "error";
    //   setGetDataFromServer({ ...getDataFromServer });
    // }
  };
  useEffect(() => {
    if (typeShippment.package == "1") {
      setType("חבילה");
    }
    if (typeShippment.envelope == "1") {
      setType("מעטפה");
    }
    if (typeShippment.other == "1") {
      setType(textArea);
    }
    // console.log(type);
  }, [typeShippment, textArea]);

  async function switchUpdateOrNew() {
    let cloneResultData;

    if (!getDataFromServer.track_id && !token) {
      //cloneResultData = _.cloneDeep(data);
    }
    if (exitAddress && detailsOfPackage.length) {
      if (getDataFromServer.track_id || token) {
        if (cloneResultData !== data || token) {
          // const result = await updateServer({
          //   nameAndPhone,
          //   checkboxValue,
          //   detailsOfPackage,
          //   getDataFromServer,
          //   setGetDataFromServer,
          //   exitAddress,
          //   time,
          //   token,
          //});
        }
        {
          return;
        }
      } else {
        fetchData();
      }
    }
  }

  const checkboxHandlerTruck = (e) => {
    e.target.checked
      ? (checkboxValue.TruckChecked = "1")
      : (checkboxValue.TruckChecked = "0");
    setCheckboxValue({ ...checkboxValue });
    if (e.target.checked) {
      setDetails(t("STEPS_NUM_2_TYPE_PACKAGE_SELECT_3_DETAIL"));
      setErrorCarType("");
      setIsSelectVehicle(true);
    } else {
      setDetails("");
    }

    //setIsShownT((current) => !current);

    //setIsShownM(false);
    //setIsShownC(false);
  };
  const checkboxHandlerTypeShippmentEnvelope = (e) => {
    if (e.target.checked) {
      typeShippment.other = "0";
      typeShippment.package = "0";
      typeShippment.envelope = "1";
      setIsSelectShipment(true);
    } else {
      return;
    }

    setTypeShippment({ ...typeShippment });
  };
  const checkboxHandlerTypeShippmentPackage = (e) => {
    if (e.target.checked) {
      typeShippment.other = "0";
      typeShippment.package = "1";
      typeShippment.envelope = "0";
      setIsSelectShipment(true);
    } else {
      return;
    }

    setTypeShippment({ ...typeShippment });
  };
  const checkboxHandlerTypeShippmentOther = (e) => {
    if (e.target.checked) {
      typeShippment.other = "1";
      typeShippment.package = "0";
      typeShippment.envelope = "0";
      setShowTextArea(true);
      setIsSelectShipment(true);
    } else {
      return;
    }

    setTypeShippment({ ...typeShippment });
  };
  const checkboxHandlerMotorcycle = (e) => {
    e.target.checked
      ? (checkboxValue.MotorcycleChecked = "1")
      : (checkboxValue.MotorcycleChecked = "0");
    setCheckboxValue({ ...checkboxValue });
    //setIsShownT((current) => !current);
    if (e.target.checked) {
      setDetails(t("STEPS_NUM_2_TYPE_PACKAGE_SELECT_1_DETAIL"));
      setErrorCarType("");
      setIsSelectVehicle(true);
    } else {
      setDetails("");
    }
  };
  const checkboxHandlerCar = (e) => {
    e.target.checked
      ? (checkboxValue.CarChecked = "1")
      : (checkboxValue.CarChecked = "0");
    setCheckboxValue({ ...checkboxValue });

    if (e.target.checked) {
      setDetails(t("STEPS_NUM_2_TYPE_PACKAGE_SELECT_2_DETAIL"));
      setErrorCarType("");
      setIsSelectVehicle(true);
    } else {
      setDetails("");
    }
    //setIsShownT((current) => !current);
    // setIsShownC((current) => !current);
    // setIsShownM(false);
    // setIsShownT(false);
  };

  const styleLabelCheckBox = (check) => {
    if (check !== "1") {
      return {
        fontFamily: "Heebo",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "17px",
        lineHeight: "0",
        color: "#81838C",
      };
    } else {
      return {
        fontFamily: "Heebo",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "17px",
        lineHeight: "0",
        color: "#0D152E",
      };
    }
  };

  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });
  const isRTL = (s) => {
    if (s !== "") {
      var ltrChars =
          "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
          "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
        rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
        rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]");

      return rtlDirCheck.test(s);
    }
    return true;
  };

  return (
    <div
      className="row infoSteps"
      style={{
        maxWidth: !isMobile && "595px",
        height: !isMobile ? "500px" : "850px",
      }}
    >
      <div className="full_width_checkboxFlex">
        <div
          style={{
            marginBottom: "25px",
            paddingRight: !isTabletOrMobile && "1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: isTabletOrMobile && "center",
          }}
        >
          <h2 className="h1Step">
            {t("STEPS_NUM_2_TYPE_PACKAGE_HEADER_LINE_1")}
          </h2>
          <p className="pStep2">
            {t("STEPS_NUM_2_TYPE_PACKAGE_HEADER_LINE_2")}
          </p>
          <div
            style={{
              marginTop: "10px",
              paddingRight: !isTabletOrMobile && "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: isTabletOrMobile && "center",
              color: "#81838C",
              fontfamily: "Heebo",
              fontstyle: "normal",
              fontweight: "400",
              fontsize: "16px",
              lineheight: "23px",
              /* text-align: center; */
              letterspacing: "-0.66px",
            }}
          >
            {details}
          </div>
        </div>
        <div
          style={{
            display: !isMobile && showTextArea && "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormGroup
            className="checkboxFlex"
            style={{ direction: !isTabletOrMobile && "initial" }}
          >
            <FormControlLabel
              sx={{
                "& .MuiTypography-root": styleLabelCheckBox(
                  checkboxValue.MotorcycleChecked
                ),
              }}
              style={{
                width: !isMobile && "30%",
                border:
                  checkboxValue.MotorcycleChecked == "1" && "1px solid #583DFF",
              }}
              className="FormLabelCheckBox"
              control={
                <Checkbox
                  icon={<CheckCircleIcon />}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      width: 20,
                      height: 20,
                      color:
                        checkboxValue.MotorcycleChecked == "1"
                          ? "#583DFF"
                          : "#81838C",
                      borderRadius: 20,
                    },
                  }}
                  checkedIcon={<CheckCircleSharpIcon />}
                  checked={checkboxValue.MotorcycleChecked == "1" && true}
                  onChange={checkboxHandlerMotorcycle}
                />
              }
              label={t("STEPS_NUM_2_TYPE_PACKAGE_SELECT_1")}
            />

            <FormControlLabel
              sx={{
                "& .MuiTypography-root": styleLabelCheckBox(
                  checkboxValue.CarChecked
                ),
              }}
              className="FormLabelCheckBox"
              style={{
                width: !isMobile && "30%",
                gap: isTabletOrMobile && "21px",
                border: checkboxValue.CarChecked == "1" && "1px solid #583DFF",
              }}
              control={
                <Checkbox
                  icon={<CheckCircleIcon />}
                  sx={{
                    "& .Mui-checked": {
                      color: "#583DFF",
                    },
                    "& .MuiSvgIcon-root": {
                      width: 20,
                      height: 20,
                      color:
                        checkboxValue.CarChecked == "1" ? "#583DFF" : "#81838C",
                      borderRadius: 20,
                    },
                  }}
                  checkedIcon={<CheckCircleSharpIcon />}
                  checked={checkboxValue.CarChecked == "1" && true}
                  onChange={checkboxHandlerCar}
                />
              }
              label={t("STEPS_NUM_2_TYPE_PACKAGE_SELECT_2")}
            />

            <FormControlLabel
              sx={{
                "& .MuiTypography-root": styleLabelCheckBox(
                  checkboxValue.TruckChecked
                ),
              }}
              className="FormLabelCheckBox"
              style={{
                width: !isMobile && "30%",
                border:
                  checkboxValue.TruckChecked == "1" && "1px solid #583DFF",
              }}
              control={
                <Checkbox
                  icon={<CheckCircleIcon />}
                  sx={{
                    "& .Mui-checked": {
                      color: "#583DFF",
                    },
                    "& .MuiSvgIcon-root": {
                      width: 20,
                      height: 20,
                      color:
                        checkboxValue.TruckChecked == "1"
                          ? "#583DFF"
                          : "#81838C",
                      borderRadius: 20,
                    },
                  }}
                  checkedIcon={<CheckCircleSharpIcon />}
                  checked={checkboxValue.TruckChecked == "1" && true}
                  onChange={checkboxHandlerTruck}
                />
              }
              label={t("STEPS_NUM_2_TYPE_PACKAGE_SELECT_3")}
            /> 
          </FormGroup>
          {errorCarType && <div style={{ color: 'red', display: 'flex', flexWrap: 'wrap', width: '100%', marginTop: '8px'  }}>{errorCarType}</div>}

          {/* { <div style={{ marginTop: "35px" }}>ddddd</div> }
          {isShownT && <div style={{ marginTop: "35px" }}>{details}</div>} */}

          <div style={{ marginTop: "32px" }}>
            <div
              style={{
                marginBottom: !showTextArea ? "25px" : "10px",
                paddingRight: !isTabletOrMobile && "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: isTabletOrMobile && "center",
              }}
            >
              <h2 className="h1Step">
                {t("STEPS_NUM_2_TYPE_PACKAGE_CONTENT_HEADER_LINE_1")}
              </h2>
              {/* <p className="pStep2">
                {t("STEPS_NUM_2_TYPE_PACKAGE_HEADER_LINE_2")}
              </p> */}
            </div>
            {showTextArea && (
              <div
                onClick={() => setShowTextArea(!showTextArea)}
                style={{ height: "40px", cursor: "pointer" }}
              >
                <ArrowRightAltIcon
                  style={{ color: "#1976d2" }}
                  className="ArrowEN"
                />
              </div>
            )}
            {!showTextArea ? (
              <>
                <div>
                  <FormGroup className="checkboxFlex">
                    <FormControlLabel
                      sx={{
                        "& .MuiTypography-root": styleLabelCheckBox(
                          typeShippment.package
                        ),
                      }}
                      style={{
                        width: !isMobile && "30%",
                        height: !isMobile && "106px",
                        display: !isMobile && "flex",
                        flexDirection: !isMobile && "column",
                        justifyContent: !isMobile && "center",
                        border:
                          typeShippment.package == "1" && "1px solid #583DFF",
                      }}
                      className="FormLabelCheckBox"
                      control={
                        <Checkbox
                          icon={<img src={packageImg} alt="package" />}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              width: 20,
                              height: 20,
                              display: "none",

                              color:
                                typeShippment.package == "1"
                                  ? "#583DFF"
                                  : "#81838C",
                              borderRadius: 20,
                            },
                          }}
                          checkedIcon={<img src={packageImg} alt="package" />}
                          checked={typeShippment.package == "1" && true}
                          onChange={checkboxHandlerTypeShippmentPackage}
                        />
                      }
                      label={t(
                        "STEPS_NUM_2_TYPE_PACKAGE_CONTENT_OPTION_PACKAGE"
                      )}
                    />

                    <FormControlLabel
                      sx={{
                        "& .MuiTypography-root": styleLabelCheckBox(
                          typeShippment.envelope
                        ),
                      }}
                      className="FormLabelCheckBox"
                      style={{
                        height: !isMobile && "106px",
                        width: !isMobile && "30%",
                        display: !isMobile && "flex",
                        flexDirection: !isMobile && "column",
                        justifyContent: !isMobile && "center",
                        gap: isTabletOrMobile && "21px",
                        border:
                          typeShippment.envelope == "1" && "1px solid #583DFF",
                      }}
                      control={
                        <Checkbox
                          // style={{display:"none"}}
                          icon={<img src={envelope} alt="envelope" />}
                          sx={{
                            "& .Mui-checked": {
                              color: "#583DFF",
                              display: "none",
                            },
                            "& .MuiSvgIcon-root": {
                              width: 20,
                              height: 20,
                              display: "none",
                              height: !isMobile && "106px",
                              color:
                                typeShippment.envelope == "1"
                                  ? "#583DFF"
                                  : "#81838C",
                              borderRadius: 20,
                            },
                          }}
                          checkedIcon={<img src={envelope} alt="envelope" />}
                          checked={typeShippment.envelope == "1" && true}
                          onChange={checkboxHandlerTypeShippmentEnvelope}
                        />
                      }
                      label={t(
                        "STEPS_NUM_2_TYPE_PACKAGE_CONTENT_OPTION_ENVELOPE"
                      )}
                    />

                    <FormControlLabel
                      sx={{
                        "& .MuiTypography-root": styleLabelCheckBox(
                          typeShippment.other
                        ),
                      }}
                      className="FormLabelCheckBox"
                      style={{
                        height: !isMobile && "106px",
                        width: !isMobile && "30%",
                        border:
                          typeShippment.other == "1" && "1px solid #583DFF",
                      }}
                      control={
                        <Checkbox
                          style={{ padding: "0", margin: "8px" }}
                          icon={<span style={{ height: "90px" }} />}
                          sx={{
                            "& .MuiCheckbox-root": {
                              padding: "0",
                              margin: "5px",
                            },
                            "& .Mui-checked": {
                              color: "#583DFF",
                              width: "100%",
                              display: "none",
                            },
                            "& .MuiSvgIcon-root": {
                              width: 20,
                              height: 20,
                              height: !isMobile && "106px",
                              display: "none",
                              color:
                                typeShippment.other == "1"
                                  ? "#583DFF"
                                  : "#81838C",
                              borderRadius: 20,
                            },
                          }}
                          checkedIcon={<span style={{ height: "90px" }} />}
                          checked={typeShippment.other == "1" && true}
                          onChange={checkboxHandlerTypeShippmentOther}
                        />
                      }
                      label={t("STEPS_NUM_2_TYPE_PACKAGE_CONTENT_OPTION_OTHER")}
                    />
                  </FormGroup>
                </div>
              </>
            ) : (
              <Collapse in={showTextArea}>
                <CacheProvider
                  value={
                    ltrTheme.direction === "ltr" && isRTL("")
                      ? cacheRtl
                      : cacheLtr
                  }
                >
                  <CssBaseline />
                  <TextField
                    id="outlined-multiline-static"
                    label={t("STEPS_NUM_2_TYPE_PACKAGE_CONTENT_OPTION_OTHER")}
                    style={{
                      direction:
                        ltrTheme.direction === "ltr" && isRTL("")
                          ? "rtl"
                          : "ltr",
                    }}
                    multiline
                    value={textArea}
                    onChange={(e) => {
                      setTextArea(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        direction:
                          ltrTheme.direction === "ltr" && isRTL("")
                            ? "rtl"
                            : "ltr",
                      },
                    }}
                    rows={2}
                    placeholder={t(
                      "STEPS_NUM_2_TYPE_PACKAGE_CONTENT_OPTION_OTHER_PLACEHOLDER"
                    )}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        fontFamily: "Heebo",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "37px",
                        // textAlign: "right",
                        color: "#4F5976",
                      },
                      "& fieldset": {
                        direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                      },
                    }}
                    InputProps={{
                      style: {
                        direction:
                          ltrTheme.direction === "ltr" && isRTL("")
                            ? "rtl"
                            : "ltr",
                      },
                    }}
                  />
                </CacheProvider>
              </Collapse>
            )}
          </div>
        </div>
      </div>
    </div>


  );
}
