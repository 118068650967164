import "./detailsOfPackage.css";
import Arrow from "../images/Arrow.png";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";
import { CssBaseline } from "@mui/material";
import createCache from "@emotion/cache";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import ArrowDown from "../images/arrowDown.png";
import BusinessDetailsPackage from "./businessDetailsPackage/businessDetailsPackage";
import {
  MenuItem,
  Select,
  Alert,
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useRef, useState, useEffect, useContext } from "react";
import LocationSearchInput from "./reactAutoComplete";
import MultiSteps from "./MultiSteps/MultiSteps";
import { useMediaQuery } from "react-responsive";
import { DataContext } from "../../../dataContext";
import moment from "moment";
import { useParams } from "react-router-dom";
import { injectGlobal } from "@emotion/css";
import dayjs, { Dayjs } from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TimePicker from "@mui/lab/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
// import Select from "react-select";

export default function DetailsOfPackage({ showSteps, setShowSteps }) {
  const { t } = useTranslation();
  const { token } = useParams();
  const pickUpOptions = [
    { value: "1", label: t("COLLECTION_OPTION1") },
    { value: "2", label: t("COLLECTION_OPTION2") },
    { value: "3", label: t("COLLECTION_OPTION3") },
    { value: "4", label: t("COLLECTION_OPTION4") },
  ];

  const deliveryOptions = [
    { value: "1", label: t("DELIVERY_OPTION1") },
    { value: "2", label: t("DELIVERY_OPTION2") },
    { value: "3", label: t("DELIVERY_OPTION3") },
    { value: "4", label: t("DELIVERY_OPTION4") },
  ];

  const {
    businessCostumerConnect,
    responseGetById,
    setResponseGetById,
    exitAddress,
    setExitaddress,
    errorAutoComplet,
    setErrorAutoComplet,
    getDataFromServer,
    setGetDataFromServer,
    errorDDline,
    setErrorDDline,
    errorPickUp,
    setErrorPickUp,
    address,
    setAddress,
    detailsOfPackage,
    setDetailsOfPackage,
    time,
    setTime,
    nameAndPhone,
    setNameAndPhone,
    checkboxValue,
    setCheckboxValue,
    activeStep,
    setActiveStep,
    validToken,
  } = useContext(DataContext);

  const now = new Date();
  now.setMinutes(now.getMinutes() + 1 - now.getTimezoneOffset());
  const ddL = new Date();
  ddL.setHours(ddL.getHours() + 15);

  const ref = useRef();
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const Tablet = useMediaQuery({ query: "(max-width: 800px)" });
  const [optionPickUpTime, setOptionPickUpTime] = useState("1");
  const [optionDDlineTime, setOptionDDlineTime] = useState("3");

  useEffect(() => {
    time.ddLine = optionDDlineTime;
    time.pickUpTime = optionPickUpTime;
  }, []);

  useEffect(() => {
    if (token && !responseGetById) {
      // console.log(showSteps);
      setShowSteps(true);
    }
  }, []);

  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });

  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  // console.log("language: " + ltrTheme.direction);

  const InputLabelProps = {
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "right",
    color: "#4F5976",
  };

  const startSteps = (e) => {
    e.preventDefault();
    if (
      exitAddress.cityName == "" &&
      exitAddress.streetName == "" &&
      exitAddress.streetNum == ""
    ) {
      return setErrorAutoComplet(t("ERROR_MESSAGE_AUTOCOMPLET"));
    }

    if (!errorAutoComplet) {
      setShowSteps(true);
    }
  };

  const focusClearErrors = () => {
    setErrorPickUp(false);
    setErrorDDline(false);
    setErrorAutoComplet(false);
  };
  //const [value, setValue] = (useState < Dayjs) | (null > dayjs());
  //const [value, setValue] = (React.useState < Dayjs) | (null > dayjs());
  // const [valuee, setValuee] =
  //   (React.useState < Dayjs) | (null > dayjs("2018-01-01T00:00:00.000Z"));

  const changeListPickUp = (e) => {
    console.log(e.target.value);
    if (e.target.value !== "") {
      setOptionPickUpTime(e.target.value);

      time.pickUpTime = e.target.value;
    }
  };
  const changeListDelivery = (e) => {
    if (e.target.value !== "") {
      setOptionDDlineTime(e.target.value);

      time.ddLine = e.target.value;
    }
  };

  const changePickUpTime = (e) => {
    if (isTabletOrMobile) {
      //old code - without date poup in desktop
      if (e.target.value !== "") {
        time.pickUpTime = e.target.value;
        time.ddLine = moment(new Date(time.pickUpTime))
          .add(1, "days")
          .format("YYYY-MM-DD[T]HH:mm");
        setTime({ ...time });
      } else {
        time.pickUpTime = now.toISOString().substring(0, 16);
        time.ddLine = moment().add(1, "days").format("YYYY-MM-DD[T]HH:mm");
        setTime({ ...time });
      }
    } else {
      console.log(e);
      console.log(e["$d"]);
      if (e["$d"] !== "") {
        time.pickUpTime = moment(new Date(e["$d"])).format(
          "YYYY-MM-DD[T]HH:mm"
        );
        time.ddLine = moment(new Date(time.pickUpTime))
          .add(1, "days")
          .format("YYYY-MM-DD[T]HH:mm");
        setTime({ ...time });
        console.log(time);
      } else {
        time.pickUpTime = now.toISOString().substring(0, 16);
        time.ddLine = moment().add(1, "days").format("YYYY-MM-DD[T]HH:mm");
        setTime({ ...time });
      }
    }
  };
  const checkOnFocus = (e) => {
    time.pickUpTime != e.target.value && setErrorPickUp("");
    setErrorDDline("");
  };

  const changeDdLine = (e) => {
    if (isTabletOrMobile) {
      // old
      time.ddLine = e.target.value;
      setTime({ ...time });
    } else {
      time.ddLine = moment(new Date(e["$d"])).format("YYYY-MM-DD[T]HH:mm");
      console.log(e);
      console.log(time.ddLine);
      setTime({ ...time });
    }
  };

  let height;

  function styleHeight() {
    height = "";
    if (isMobile) {
      if (errorAutoComplet || errorDDline || errorPickUp) {
        return (height += "600px");
      }
    }
  }

  const heightDetails = () => {
    let heightDetails = "";
    if (activeStep == 1 && isMobile) {
      return (heightDetails = "985px");
    }
    if (isMobile && showSteps && activeStep < 5) {
      return (heightDetails = "821px");
    }

    if (activeStep == 1 && !isTabletOrMobile) {
      return (heightDetails = "850px");
    }

    if (isMobile && activeStep == 5) {
      return (heightDetails = "745px");
    }
    if (isMobile && !showSteps) {
      return (heightDetails = "531px");
    }
    if (isTabletOrMobile && showSteps && activeStep < 5) {
      return (heightDetails = "686px");
    }

    if (isTabletOrMobile && activeStep == 5) {
      return (heightDetails = "585px");
    }
    if (isTabletOrMobile && !showSteps) {
      return (heightDetails = "645px");
    }
    if ((Tablet && !showSteps) || (activeStep == 5 && Tablet)) {
      return (heightDetails = "592px");
    }
    if (validToken && showSteps) {
      return (heightDetails = "1312px");
    }
    if (Tablet && showSteps) {
      return (heightDetails = "675px");
    } else {
      return (heightDetails = "809px");
    }
  };
  //
  const switchPaddingLefInput = () => {
    if (ltrTheme.direction === "rtl" && isMobile) {
      return "25px";
    }
  };

  return (
    <section
      className="container-fluid mobile_details_height"
      style={{ height: heightDetails(), background: !showSteps && "#F1F1F6" }}
    >
      <div className="cover">
        <div className="coverDetails" style={{ height: styleHeight() }}>
          {" "}
          {!showSteps ? (
            <div style={{ position: "relative" }}>
              <div className="details mobile_details">
                <div className="flex_header_details">
                  <h1 className="h1_mobile">
                    {" "}
                    {t("DETAILS_OF_PACKAGE_LINE_1")}{" "}
                  </h1>{" "}
                  <p className="p_details_mobile">
                    {" "}
                    {t("DETAILS_OF_PACKAGE_LINE_2")}{" "}
                  </p>{" "}
                </div>{" "}
                <div className="mobile_width_detailofpackage">
                  <Stack onFocus={focusClearErrors}>
                    <div
                      style={{
                        marginBottom: isMobile && "0px",
                        height: isTabletOrMobile && !isMobile && "111px",
                      }}
                    >
                      <LocationSearchInput
                        errorAutoComplet={errorAutoComplet}
                        setErrorAutoComplet={setErrorAutoComplet}
                        exitAddress={exitAddress}
                        setExitaddress={setExitaddress}
                        label={t("DETAILS_OF_PACKAGE_INPUT_ADDRESS")}
                        value={address.address}
                        onChange={(add) => setAddress({ address: add })}
                      />{" "}
                    </div>{" "}
                    <div
                      className="flex_date_time"
                      style={{
                        // visibility: !isTabletOrMobile ? "visible" : "hidden",
                        display: isTabletOrMobile ? "block" : "flex",
                        marginTop: isMobile
                          ? "60px"
                          : isTabletOrMobile
                          ? "0px"
                          : "60px",
                        marginBottom: isMobile
                          ? "45px"
                          : isTabletOrMobile
                          ? "0px"
                          : "65px",
                      }}
                    >
                      <CacheProvider
                        value={
                          // old - before calendar
                          ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr
                        }
                      >
                        <CssBaseline />
                        <TextField
                          id="outlined-select-currency"
                          select
                          SelectProps={{
                            IconComponent: () => (
                              <img
                                src={ArrowDown}
                                alt="arrow_down"
                                style={{ marginLeft: "7px", cursor: "pointer" }}
                              />
                            ),
                          }}
                          style={{
                            direction:
                              ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                          }}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              fontFamily: "heebo",
                            },
                            width: isMobile
                              ? 300
                              : isTabletOrMobile
                              ? "100%"
                              : "48%",
                            marginBottom: isMobile
                              ? "23px"
                              : isTabletOrMobile && "35px",
                            "& .MuiOutlinedInput-root": {
                              fontSize: "16px",
                              fontFamily: "heebo",
                              paddingLeft: switchPaddingLefInput(),
                            },
                            "& fieldset": {
                              direction:
                                ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                            },
                          }}
                          // defaultValue = {pickUpOptions[0].label}
                          InputLabelProps={{
                            style: InputLabelProps,
                            shrink: true,
                          }}
                          inputProps={{
                            style: {
                              textAlign:
                                ltrTheme.direction === "ltr" ? "right" : "left",
                              display:
                                ltrTheme.direction === "ltr" ? "flex" : "block",
                              flexDirection:
                                ltrTheme.direction === "ltr"
                                  ? "row-reverse"
                                  : "row",
                              height: isMobile
                                ? "40px"
                                : isTabletOrMobile && "40px",
                              alignItems:
                                ltrTheme.direction === "rtl" &&
                                isMobile &&
                                "flex-end",
                            },
                          }}
                          label={t("DETAILS_OF_PACKAGE_INPUT_DATE")}
                          width={300}
                          value={optionPickUpTime}
                          onChange={changeListPickUp}
                        >
                          {pickUpOptions.map((option) => (
                            <MenuItem
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          id="outlined-select-currency"
                          select
                          SelectProps={{
                            IconComponent: () => (
                              <img
                                src={ArrowDown}
                                alt="arrow_down"
                                style={{ marginLeft: "7px", cursor: "pointer" }}
                              />
                            ),
                          }}
                          style={{
                            direction:
                              ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                          }}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              fontFamily: "heebo",
                            },
                            width: isMobile
                              ? 300
                              : isTabletOrMobile
                              ? "100%"
                              : "48%",
                            marginBottom: isMobile
                              ? "23px"
                              : isTabletOrMobile && "35px",
                            "& .MuiOutlinedInput-root": {
                              fontSize: "16px",
                              fontFamily: "heebo",
                              paddingLeft: switchPaddingLefInput(),
                            },
                            "& fieldset": {},
                          }}
                          InputLabelProps={{
                            style: InputLabelProps,
                            shrink: true,
                          }}
                          inputProps={{
                            style: {
                              // "& .MuiSelect-select":{
                              direction:
                                ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                              // }
                              textAlign:
                                ltrTheme.direction === "ltr" ? "right" : "left",
                              display:
                                ltrTheme.direction === "ltr" ? "flex" : "block",
                              flexDirection:
                                ltrTheme.direction === "ltr"
                                  ? "row-reverse"
                                  : "row",
                              height: isMobile
                                ? "40px"
                                : isTabletOrMobile && "40px",
                              alignItems:
                                ltrTheme.direction === "rtl" &&
                                isMobile &&
                                "flex-end",
                            },
                          }}
                          label={t("DETAILS_OF_PACKAGE_DDLINE")}
                          width={300}
                          value={optionDDlineTime}
                          onChange={changeListDelivery}
                        >
                          {deliveryOptions.map((option) => (
                            <MenuItem
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </CacheProvider>{" "}
                    </div>
                    <Button
                      onClick={startSteps}
                      className="marginBtn"
                      style={{
                        height: "62px",
                        margin: "auto",
                        backgroundColor: "#583DFF",
                        borderRadius: "4px",
                      }}
                      variant="contained"
                    >
                      {" "}
                      <img className="Arrow" src={Arrow} alt="Arrow" />{" "}
                      {t("DETAILS_OF_PACKAGE_CONTINUE")}{" "}
                    </Button>{" "}
                  </Stack>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          ) : (
            <div
              className="details MultiSteps"
              style={{ height: isMobile && activeStep == 5 && "700px" }}
            >
              <MultiSteps />
            </div>
          )}{" "}
        </div>{" "}
      </div>{" "}
    </section>
  );
}

//old code
// import "./detailsOfPackage.css";
// import Arrow from "../images/Arrow.png";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { CacheProvider } from "@emotion/react";
// import rtlPlugin from "stylis-plugin-rtl";
// import { CssBaseline } from "@mui/material";
// import createCache from "@emotion/cache";
// import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
// import { useTranslation } from "react-i18next";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
// import {
//   Alert,
//   Autocomplete,
//   Button,
//   IconButton,
//   InputAdornment,
// } from "@mui/material";
// import React, { useRef, useState, useEffect, useContext } from "react";
// import LocationSearchInput from "./reactAutoComplete";
// import MultiSteps from "./MultiSteps/MultiSteps";
// import { useMediaQuery } from "react-responsive";
// import { DataContext } from "../../../dataContext";
// import moment from "moment";
// import { useParams } from "react-router-dom";
// import { injectGlobal } from "@emotion/css";
// import dayjs, { Dayjs } from "dayjs";

// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// //import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import TimePicker from "@mui/lab/TimePicker";
// import { DateTimePicker } from "@mui/x-date-pickers";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import MobileDatePicker from "@mui/lab/MobileDatePicker";

// export default function DetailsOfPackage({ showSteps, setShowSteps }) {
//   const { t } = useTranslation();
//   const { token } = useParams();

//   const {
//     responseGetById,
//     setResponseGetById,
//     exitAddress,
//     setExitaddress,
//     errorAutoComplet,
//     setErrorAutoComplet,
//     getDataFromServer,
//     setGetDataFromServer,
//     errorDDline,
//     setErrorDDline,
//     errorPickUp,
//     setErrorPickUp,
//     address,
//     setAddress,
//     detailsOfPackage,
//     setDetailsOfPackage,
//     time,
//     setTime,
//     nameAndPhone,
//     setNameAndPhone,
//     checkboxValue,
//     setCheckboxValue,
//     activeStep,
//     setActiveStep,
//   } = useContext(DataContext);

//   const now = new Date();
//   now.setMinutes(now.getMinutes() + 1 - now.getTimezoneOffset());
//   const ddL = new Date();
//   ddL.setHours(ddL.getHours() + 15);

//   const ref = useRef();
//   const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
//   const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });
//   const Tablet = useMediaQuery({ query: "(max-width: 800px)" });

//   useEffect(() => {
//     if (token && !responseGetById) {
//       console.log(showSteps);
//       // console.log('f');
//       setShowSteps(true);
//     }
//   }, []);

//   const ltrTheme =
//     document.documentElement.dir === "rtl"
//       ? createTheme({ direction: "rtl" })
//       : createTheme({ direction: "ltr" });
//   const cacheLtr = createCache({
//     key: "muiltr",
//   });
//   const cacheRtl = createCache({
//     key: "muirtl",
//     stylisPlugins: [rtlPlugin],
//   });
//   console.log("language: " + ltrTheme.direction);

//   const InputLabelProps = {
//     fontFamily: "Heebo",
//     fontStyle: "normal",
//     fontWeight: "500",
//     fontSize: "16px",
//     lineHeight: "22px",
//     textAlign: "right",
//     color: "#4F5976",
//   };

//   // console.log(time);

//   const startSteps = (e) => {
//     e.preventDefault();
//     // console.log(exitAddress);
//     if (
//       exitAddress.cityName == "" &&
//       exitAddress.streetName == "" &&
//       exitAddress.streetNum == ""
//     ) {
//       return setErrorAutoComplet(t("ERROR_MESSAGE_AUTOCOMPLET"));
//     }

//     if (!errorAutoComplet) {
//       setShowSteps(true);
//     }
//   };

//   const focusClearErrors = () => {
//     setErrorPickUp(false);
//     setErrorDDline(false);
//     setErrorAutoComplet(false);
//   };
//   //const [value, setValue] = (useState < Dayjs) | (null > dayjs());
//   //const [value, setValue] = (React.useState < Dayjs) | (null > dayjs());
//   // const [valuee, setValuee] =
//   //   (React.useState < Dayjs) | (null > dayjs("2018-01-01T00:00:00.000Z"));

//   const changePickUpTime = (e) => {
//     if (isTabletOrMobile) {
//       //old code - without date poup in desktop
//       if (e.target.value !== "") {
//         time.pickUpTime = e.target.value;
//         time.ddLine = moment(new Date(time.pickUpTime))
//           .add(1, "days")
//           .format("YYYY-MM-DD[T]HH:mm");
//         setTime({ ...time });
//       } else {
//         time.pickUpTime = now.toISOString().substring(0, 16);
//         time.ddLine = moment().add(1, "days").format("YYYY-MM-DD[T]HH:mm");
//         setTime({ ...time });
//       }
//     } else {
//       console.log(e);
//       console.log(e["$d"]);
//       if (e["$d"] !== "") {
//         time.pickUpTime = moment(new Date(e["$d"])).format(
//           "YYYY-MM-DD[T]HH:mm"
//         );
//         time.ddLine = moment(new Date(time.pickUpTime))
//           .add(1, "days")
//           .format("YYYY-MM-DD[T]HH:mm");
//         setTime({ ...time });
//         console.log(time);
//       } else {
//         time.pickUpTime = now.toISOString().substring(0, 16);
//         time.ddLine = moment().add(1, "days").format("YYYY-MM-DD[T]HH:mm");
//         setTime({ ...time });
//       }
//     }
//   };
//   const checkOnFocus = (e) => {
//     time.pickUpTime != e.target.value && setErrorPickUp("");
//     setErrorDDline("");
//   };

//   const changeDdLine = (e) => {
//     if (isTabletOrMobile) {
//       // old
//       time.ddLine = e.target.value;
//       setTime({ ...time });
//     } else {
//       time.ddLine = moment(new Date(e["$d"])).format("YYYY-MM-DD[T]HH:mm");
//       console.log(e);
//       console.log(time.ddLine);
//       setTime({ ...time });
//     }
//   };

//   let height;

//   function styleHeight() {
//     height = "";
//     if (isMobile) {
//       if (errorAutoComplet || errorDDline || errorPickUp) {
//         return (height += "600px");
//       }
//     }
//   }

//   const heightDetails = () => {
//     let heightDetails = "";
//     if (isMobile && showSteps && activeStep < 5) {
//       return (heightDetails = "821px");
//     }
//     if (isMobile && activeStep == 5) {
//       return (heightDetails = "745px");
//     }
//     if (isMobile && !showSteps) {
//       return (heightDetails = "531px");
//     }
//     if (isTabletOrMobile && showSteps && activeStep < 5) {
//       return (heightDetails = "686px");
//     }

//     if (isTabletOrMobile && activeStep == 5) {
//       return (heightDetails = "585px");
//     }
//     if (isTabletOrMobile && !showSteps) {
//       return (heightDetails = "645px");
//     }
//     if ((Tablet && !showSteps) || (activeStep == 5 && Tablet)) {
//       return (heightDetails = "592px");
//     }
//     if (Tablet && showSteps) {
//       return (heightDetails = "675px");
//     } else {
//       return (heightDetails = "809px");
//     }
//   };

//   const switchPaddingLefInput = () => {
//     if (ltrTheme.direction === "rtl" && isMobile) {
//       return "25px";
//     }
//   };

//   return (
//     <section
//       className="container-fluid mobile_details_height"
//       style={{ height: heightDetails(), background: !showSteps && "#F1F1F6" }}
//     >
//       <div className="cover">
//         <div className="coverDetails" style={{ height: styleHeight() }}>
//           {" "}
//           {!showSteps ? (
//             <div style={{ position: "relative" }}>
//               <div className="details mobile_details">
//                 <div className="flex_header_details">
//                   <h1 className="h1_mobile">
//                     {" "}
//                     {t("DETAILS_OF_PACKAGE_LINE_1")}{" "}
//                   </h1>{" "}
//                   <p className="p_details_mobile">
//                     {" "}
//                     {t("DETAILS_OF_PACKAGE_LINE_2")}{" "}
//                   </p>{" "}
//                 </div>{" "}
//                 <div className="mobile_width_detailofpackage">
//                   <Stack onFocus={focusClearErrors}>
//                     <div
//                       style={{
//                         marginBottom: isMobile && "0px",
//                         height: isTabletOrMobile && !isMobile && "111px",
//                       }}
//                     >
//                       <LocationSearchInput
//                         errorAutoComplet={errorAutoComplet}
//                         setErrorAutoComplet={setErrorAutoComplet}
//                         exitAddress={exitAddress}
//                         setExitaddress={setExitaddress}
//                         label={t("DETAILS_OF_PACKAGE_INPUT_ADDRESS")}
//                         value={address}
//                         onChange={(address) => setAddress(address)}
//                       />{" "}
//                     </div>
//                     {isTabletOrMobile ? (
//                       <div
//                         className="flex_date_time"
//                         style={{
//                           visibility: isTabletOrMobile ? "visible" : "hidden",
//                           display: isTabletOrMobile ? "block" : "flex",
//                           marginTop: isMobile
//                             ? "60px"
//                             : isTabletOrMobile
//                             ? "0px"
//                             : "60px",
//                           marginBottom: isMobile
//                             ? "45px"
//                             : isTabletOrMobile
//                             ? "0px"
//                             : "65px",
//                         }}
//                       >
//                         <CacheProvider
//                           value={
//                             // old - before calendar
//                             ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr

//                             //ltrTheme.direction === "ltr" ? cacheLtr : cacheRtl
//                           }
//                         >
//                           <CssBaseline /> {/* old - before calendar */}
//                           <TextField
//                             error={!!errorPickUp}
//                             className="textFieldLtr"
//                             label={t("DETAILS_OF_PACKAGE_INPUT_DATE")}
//                             helperText={errorPickUp}
//                             onFocus={checkOnFocus}
//                             type="datetime-local"
//                             inputProps={{
//                               min: now.toISOString().substring(0, 16),
//                               style: {
//                                 textAlign:
//                                   ltrTheme.direction === "ltr"
//                                     ? "right"
//                                     : "left",
//                                 display:
//                                   ltrTheme.direction === "ltr"
//                                     ? "flex"
//                                     : "block",
//                                 flexDirection:
//                                   ltrTheme.direction === "ltr"
//                                     ? "row-reverse"
//                                     : "row",
//                                 height: isMobile
//                                   ? "40px"
//                                   : isTabletOrMobile && "40px",
//                                 alignItems:
//                                   ltrTheme.direction === "rtl" &&
//                                   isMobile &&
//                                   "flex-end",
//                               },
//                             }}
//                             InputProps={{
//                               startAdornment: (
//                                 <InputAdornment
//                                   style={{ cursor: "pointer" }}
//                                   position="start"
//                                 >
//                                   <CalendarMonthIcon />
//                                 </InputAdornment>
//                               ),
//                             }}
//                             value={
//                               time.pickUpTime
//                                 ? time.pickUpTime
//                                 : now.toISOString().substring(0, 16)
//                             }
//                             onChange={changePickUpTime}
//                             sx={{
//                               "& .MuiFormHelperText-root": {
//                                 fontFamily: "heebo",
//                               },
//                               width: isMobile
//                                 ? 300
//                                 : isTabletOrMobile
//                                 ? "100%"
//                                 : "48%",
//                               marginBottom: isMobile
//                                 ? "23px"
//                                 : isTabletOrMobile && "35px",
//                               "& .MuiOutlinedInput-root": {
//                                 fontSize: "16px",
//                                 fontFamily: "heebo",
//                                 paddingLeft: switchPaddingLefInput(),
//                               },
//                               "& fieldset": {
//                                 direction:
//                                   ltrTheme.direction === "rtl" ? "ltr" : "ltr",
//                               },
//                             }}
//                             InputLabelProps={{
//                               style: InputLabelProps,
//                               shrink: true,
//                             }}
//                             // />
//                           />
//                           {/* old - before calendar */}
//                           <TextField
//                             error={!!errorDDline}
//                             helperText={errorDDline ? errorDDline : ""}
//                             onFocus={() => setErrorDDline("")}
//                             label={t("DETAILS_OF_PACKAGE_DDLINE")}
//                             type="datetime-local"
//                             value={
//                               time.ddLine
//                                 ? time.ddLine
//                                 : moment(new Date(time.pickUpTime))
//                                     .add(1, "days")
//                                     .format("YYYY-MM-DD[T]HH:mm")
//                             }
//                             onChange={changeDdLine}
//                             sx={{
//                               "& .MuiFormHelperText-root": {
//                                 fontFamily: "heebo",
//                               },
//                               width: isMobile
//                                 ? 300
//                                 : isTabletOrMobile
//                                 ? "100%"
//                                 : "48%",
//                               marginBottom: isMobile
//                                 ? "23px"
//                                 : isTabletOrMobile && "35px",
//                               "& .MuiOutlinedInput-root": {
//                                 fontSize: "16px",

//                                 fontFamily: "heebo",
//                                 paddingLeft: switchPaddingLefInput(),
//                               },
//                               "& fieldset": {
//                                 direction:
//                                   ltrTheme.direction === "rtl" ? "ltr" : "ltr",
//                               },
//                             }}
//                             InputProps={{
//                               startAdornment: (
//                                 <InputAdornment
//                                   style={{ cursor: "pointer" }}
//                                   position="start"
//                                 >
//                                   <CalendarMonthIcon />
//                                 </InputAdornment>
//                               ),
//                             }}
//                             inputProps={{
//                               min: time.pickUpTime
//                                 ? moment(new Date(time.pickUpTime))
//                                     .add(1, "days")
//                                     .format("YYYY-MM-DD[T]HH:mm")
//                                 : moment(new Date())
//                                     .add(1, "days")
//                                     .format("YYYY-MM-DD[T]HH:mm"),
//                               style: {
//                                 textAlign:
//                                   ltrTheme.direction === "ltr"
//                                     ? "right"
//                                     : "left",
//                                 display:
//                                   ltrTheme.direction === "ltr"
//                                     ? "flex"
//                                     : "block",
//                                 flexDirection:
//                                   ltrTheme.direction === "ltr"
//                                     ? "row-reverse"
//                                     : "row",
//                                 height: isMobile
//                                   ? "40px"
//                                   : isTabletOrMobile && "40px",
//                                 alignItems:
//                                   ltrTheme.direction === "rtl" &&
//                                   isMobile &&
//                                   "flex-end",
//                               },
//                             }}
//                             InputLabelProps={{
//                               style: InputLabelProps,

//                               shrink: true,
//                             }}
//                           />{" "}
//                         </CacheProvider>{" "}
//                       </div>
//                     ) : (
//                       <div
//                         className="flex_date_time"
//                         style={{
//                           visibility: !isTabletOrMobile ? "visible" : "hidden",
//                           display: isTabletOrMobile ? "block" : "flex",
//                           marginTop: isMobile
//                             ? "60px"
//                             : isTabletOrMobile
//                             ? "0px"
//                             : "60px",
//                           marginBottom: isMobile
//                             ? "45px"
//                             : isTabletOrMobile
//                             ? "0px"
//                             : "65px",
//                         }}
//                       >
//                         <CacheProvider
//                           value={
//                             // old - before calendar
//                             ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr

//                             //ltrTheme.direction === "ltr" ? cacheLtr : cacheRtl
//                           }
//                         >
//                           <CssBaseline />{" "}
//                           <LocalizationProvider dateAdapter={AdapterDayjs}>
//                             <DateTimePicker
//                               showToolbar={false}
//                               renderInput={(params) => (
//                                 <TextField {...params} />
//                               )}
//                               error={!!errorPickUp}
//                               label={t("DETAILS_OF_PACKAGE_INPUT_DATE")}
//                               helperText={errorPickUp}
//                               onFocus={checkOnFocus}
//                               type="datetime-local"
//                               InputProps={{
//                                 min: now.toISOString().substring(0, 16),
//                                 style: {
//                                   //marginLeft: !isTabletOrMobile && "10px",

//                                   marginBottom: isMobile
//                                     ? "23px"
//                                     : isTabletOrMobile && "35px",
//                                   textAlign:
//                                     ltrTheme.direction === "ltr"
//                                       ? "right"
//                                       : "left",
//                                   // display:
//                                   //   ltrTheme.direction === "ltr"
//                                   //     ? "flex"
//                                   //     : "block",
//                                   // flexDirection:
//                                   //   ltrTheme.direction === "ltr"
//                                   //     ? "row-reverse"
//                                   //     : "row",
//                                   // height: isMobile
//                                   //   ? "40px"
//                                   //   : isTabletOrMobile && "40px",
//                                   alignItems:
//                                     ltrTheme.direction === "rtl" &&
//                                     isMobile &&
//                                     "flex-end",
//                                 },

//                                 startAdornment: (
//                                   <InputAdornment
//                                     style={{ cursor: "pointer" }}
//                                     position="start"
//                                   >
//                                     {/* <CalendarMonthIcon /> */}
//                                   </InputAdornment>
//                                 ),
//                               }}
//                               value={
//                                 time.pickUpTime
//                                   ? time.pickUpTime
//                                   : now.toISOString().substring(0, 16)
//                               }
//                               onChange={changePickUpTime}
//                               style={{
//                                 "& .MuiOutlinedInput-root": {
//                                   margin: "50px",
//                                   marginBottom: isMobile
//                                     ? "23px"
//                                     : isTabletOrMobile && "35px",
//                                 },
//                               }}
//                               sx={{
//                                 "& .MuiOutlinedInput-root": {
//                                   margin: "50px",
//                                   marginBottom: isMobile
//                                     ? "23px"
//                                     : isTabletOrMobile && "35px",
//                                 },
//                                 "& .MuiFormHelperText-root": {
//                                   fontFamily: "heebo",
//                                 },
//                                 width: isMobile
//                                   ? 300
//                                   : isTabletOrMobile
//                                   ? "100%"
//                                   : "48%",
//                                 marginBottom: isMobile
//                                   ? "23px"
//                                   : isTabletOrMobile && "35px",
//                                 "& fieldset": {
//                                   direction:
//                                     ltrTheme.direction === "rtl"
//                                       ? "ltr"
//                                       : "rtl",
//                                 },
//                               }}
//                               InputLabelProps={{
//                                 style: InputLabelProps,
//                                 shrink: true,
//                               }}
//                             />
//                           </LocalizationProvider>
//                           <div style={{ width: "40px" }}></div>
//                           <LocalizationProvider dateAdapter={AdapterDayjs}>
//                             <DateTimePicker
//                               showToolbar={false}
//                               renderInput={(params) => (
//                                 <TextField {...params} />
//                               )}
//                               error={!!errorDDline}
//                               helperText={errorDDline ? errorDDline : ""}
//                               onFocus={() => setErrorDDline("")}
//                               label={t("DETAILS_OF_PACKAGE_DDLINE")}
//                               type="datetime-local"
//                               value={
//                                 time.ddLine
//                                   ? time.ddLine
//                                   : moment(new Date(time.pickUpTime))
//                                       .add(1, "days")
//                                       .format("YYYY-MM-DD[T]HH:mm")
//                               }
//                               onChange={changeDdLine}
//                               sx={{
//                                 "& .MuiFormHelperText-root": {
//                                   fontFamily: "heebo",
//                                 },
//                                 width: isMobile
//                                   ? 300
//                                   : isTabletOrMobile
//                                   ? "100%"
//                                   : "48%",
//                                 marginBottom: isMobile
//                                   ? "23px"
//                                   : isTabletOrMobile && "35px",
//                                 "& .MuiOutlinedInput-root": {
//                                   fontSize: "16px",
//                                   fontFamily: "heebo",
//                                   paddingLeft: switchPaddingLefInput(),
//                                 },
//                                 "& fieldset": {
//                                   direction:
//                                     ltrTheme.direction === "rtl"
//                                       ? "rtr"
//                                       : "ltr",
//                                 },

//                                 // "& .muirtl-9yjdhh-MuiDialogContent-root:first-of-type":
//                                 //   {
//                                 //     direction: "rtl",
//                                 //   },
//                               }}
//                               InputProps={{
//                                 min: time.pickUpTime
//                                   ? moment(new Date(time.pickUpTime))
//                                       .add(1, "days")
//                                       .format("YYYY-MM-DD[T]HH:mm")
//                                   : moment(new Date())
//                                       .add(1, "days")
//                                       .format("YYYY-MM-DD[T]HH:mm"),
//                                 style: {
//                                   marginBottom: isMobile
//                                     ? "23px"
//                                     : isTabletOrMobile && "35px",

//                                   // margin: "10px",
//                                   textAlign:
//                                     ltrTheme.direction === "ltr"
//                                       ? "right"
//                                       : "left",
//                                   // display:
//                                   //   ltrTheme.direction === "ltr"
//                                   //     ? "flex"
//                                   //     : "block",
//                                   // flexDirection:
//                                   //   ltrTheme.direction === "ltr"
//                                   //     ? "row-reverse"
//                                   //     : "row",
//                                   // height: isMobile
//                                   //   ? "40px"
//                                   //   : isTabletOrMobile && "40px",
//                                   alignItems:
//                                     ltrTheme.direction === "rtl" &&
//                                     isMobile &&
//                                     "flex-end",
//                                 },
//                                 startAdornment: (
//                                   <InputAdornment
//                                     style={{ cursor: "pointer" }}
//                                     position="start"
//                                   >
//                                     {/* <CalendarMonthIcon /> */}
//                                   </InputAdornment>
//                                 ),
//                               }}
//                               InputLabelProps={{
//                                 style: InputLabelProps,
//                                 shrink: true,
//                               }}
//                             />
//                           </LocalizationProvider>
//                           {/* old - before calendar */}
//                           {/* <TextField
//                                                               error={!!errorPickUp}
//                                                               className="textFieldLtr"
//                                                               label={t("DETAILS_OF_PACKAGE_INPUT_DATE")}
//                                                               helperText={errorPickUp}
//                                                               onFocus={checkOnFocus}
//                                                               type="datetime-local"
//                                                               inputProps={{
//                                                                 min: now.toISOString().substring(0, 16),
//                                                                 style: {
//                                                                   textAlign:
//                                                                     ltrTheme.direction === "ltr" ? "right" : "left",
//                                                                   display:
//                                                                     ltrTheme.direction === "ltr" ? "flex" : "block",
//                                                                   flexDirection:
//                                                                     ltrTheme.direction === "ltr"
//                                                                       ? "row-reverse"
//                                                                       : "row",
//                                                                   height: isMobile
//                                                                     ? "40px"
//                                                                     : isTabletOrMobile && "40px",
//                                                                   alignItems:
//                                                                     ltrTheme.direction === "rtl" &&
//                                                                     isMobile &&
//                                                                     "flex-end",
//                                                                 },
//                                                               }}
//                                                               InputProps={{
//                                                                 startAdornment: (
//                                                                   <InputAdornment
//                                                                     style={{ cursor: "pointer" }}
//                                                                     position="start"
//                                                                   >
//                                                                     <CalendarMonthIcon />
//                                                                   </InputAdornment>
//                                                                 ),
//                                                               }}
//                                                               value={
//                                                                 time.pickUpTime
//                                                                   ? time.pickUpTime
//                                                                   : now.toISOString().substring(0, 16)
//                                                               }
//                                                               onChange={changePickUpTime}
//                                                               sx={{
//                                                                 "& .MuiFormHelperText-root": {
//                                                                   fontFamily: "heebo",
//                                                                 },
//                                                                 width: isMobile
//                                                                   ? 300
//                                                                   : isTabletOrMobile
//                                                                   ? "100%"
//                                                                   : "48%",
//                                                                 marginBottom: isMobile
//                                                                   ? "23px"
//                                                                   : isTabletOrMobile && "35px",
//                                                                 "& .MuiOutlinedInput-root": {
//                                                                   fontSize: "16px",
//                                                                   fontFamily: "heebo",
//                                                                   paddingLeft: switchPaddingLefInput(),
//                                                                 },
//                                                                 "& fieldset": {
//                                                                   direction:
//                                                                     ltrTheme.direction === "rtl" ? "ltr" : "ltr",
//                                                                 },
//                                                               }}
//                                                               InputLabelProps={{
//                                                                 style: InputLabelProps,
//                                                                 shrink: true,
//                                                               }}
//                                                               // />
//                                                             /> */}
//                           {/* old - before calendar */}
//                           {/* <TextField
//                                                               error={!!errorDDline}
//                                                               helperText={errorDDline ? errorDDline : ""}
//                                                               onFocus={() => setErrorDDline("")}
//                                                               label={t("DETAILS_OF_PACKAGE_DDLINE")}
//                                                               type="datetime-local"
//                                                               value={
//                                                                 time.ddLine
//                                                                   ? time.ddLine
//                                                                   : moment(new Date(time.pickUpTime))
//                                                                       .add(1, "days")
//                                                                       .format("YYYY-MM-DD[T]HH:mm")
//                                                               }
//                                                               onChange={changeDdLine}
//                                                               sx={{
//                                                                 "& .MuiFormHelperText-root": {
//                                                                   fontFamily: "heebo",
//                                                                 },
//                                                                 width: isMobile
//                                                                   ? 300
//                                                                   : isTabletOrMobile
//                                                                   ? "100%"
//                                                                   : "48%",
//                                                                 marginBottom: isMobile
//                                                                   ? "23px"
//                                                                   : isTabletOrMobile && "35px",
//                                                                 "& .MuiOutlinedInput-root": {
//                                                                   fontSize: "16px",

//                                                                   fontFamily: "heebo",
//                                                                   paddingLeft: switchPaddingLefInput(),
//                                                                 },
//                                                                 "& fieldset": {
//                                                                   direction:
//                                                                     ltrTheme.direction === "rtl" ? "ltr" : "ltr",
//                                                                 },
//                                                               }}
//                                                               InputProps={{
//                                                                 startAdornment: (
//                                                                   <InputAdornment
//                                                                     style={{ cursor: "pointer" }}
//                                                                     position="start"
//                                                                   >
//                                                                     <CalendarMonthIcon />
//                                                                   </InputAdornment>
//                                                                 ),
//                                                               }}
//                                                               inputProps={{
//                                                                 min: time.pickUpTime
//                                                                   ? moment(new Date(time.pickUpTime))
//                                                                       .add(1, "days")
//                                                                       .format("YYYY-MM-DD[T]HH:mm")
//                                                                   : moment(new Date())
//                                                                       .add(1, "days")
//                                                                       .format("YYYY-MM-DD[T]HH:mm"),
//                                                                 style: {
//                                                                   textAlign:
//                                                                     ltrTheme.direction === "ltr" ? "right" : "left",
//                                                                   display:
//                                                                     ltrTheme.direction === "ltr" ? "flex" : "block",
//                                                                   flexDirection:
//                                                                     ltrTheme.direction === "ltr"
//                                                                       ? "row-reverse"
//                                                                       : "row",
//                                                                   height: isMobile
//                                                                     ? "40px"
//                                                                     : isTabletOrMobile && "40px",
//                                                                   alignItems:
//                                                                     ltrTheme.direction === "rtl" &&
//                                                                     isMobile &&
//                                                                     "flex-end",
//                                                                 },
//                                                               }}
//                                                               InputLabelProps={{
//                                                                 style: InputLabelProps,

//                                                                 shrink: true,
//                                                               }}
//                                                             />{" "} */}
//                         </CacheProvider>{" "}
//                       </div>
//                     )}
//                     <Button
//                       onClick={startSteps}
//                       className="marginBtn"
//                       style={{
//                         height: "62px",
//                         margin: "auto",
//                         backgroundColor: "#583DFF",
//                         borderRadius: "4px",
//                       }}
//                       variant="contained"
//                     >
//                       {" "}
//                       <img className="Arrow" src={Arrow} alt="Arrow" />{" "}
//                       {t("DETAILS_OF_PACKAGE_CONTINUE")}{" "}
//                     </Button>{" "}
//                   </Stack>{" "}
//                 </div>{" "}
//               </div>{" "}
//             </div>
//           ) : (
//             <div
//               className="details MultiSteps"
//               style={{ height: isMobile && activeStep == 5 && "700px" }}
//             >
//               <MultiSteps />
//             </div>
//           )}{" "}
//         </div>{" "}
//       </div>{" "}
//     </section>
//   );
// }
