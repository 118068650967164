import * as React from "react";
import "../../homePage/detailsPackage/businessDetailsPackage/businessDetailsPackage.css";
import LocationSearchInput from "../detailsPackage/reactAutoComplete";
import FormGroup from "@mui/material/FormGroup";
import "../deliveryHistory/deliveryHistory.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button, MenuItem } from "@mui/material";
import trash from "../images/Trash.png";
import ArrowDown from "../images/arrowDown.png";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import Arrow from "../images/Arrow.png";
import ArrowColor from "../images/Arrow color.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "../images/search.png";
import { CacheProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  TextField,
  CssBaseline,
  StepContext,
  Dialog,
  Alert,
} from "@mui/material";
import createCache from "@emotion/cache";
import Slider from "@mui/material/Slider";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import _, { isString } from "lodash";
import { useState, useEffect, useContext, useRef, useCallback } from "react";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import RadioButtonUncheckedSharpIcon from "@mui/icons-material/RadioButtonUncheckedSharp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DataContext } from "../../../dataContext";
import { useTranslation } from "react-i18next";
import is from "date-fns/esm/locale/is/index.js";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

import useWebSocket, { ReadyState } from "react-use-websocket";

export default function DeliveryHistory() {
  const [ws, setWs] = useState(
    new WebSocket(
      "wss://kw0s9xiywj.execute-api.us-east-2.amazonaws.com/production"
    )
  );

  // const ws = new WebSocket(
  //   "wss://kw0s9xiywj.execute-api.us-east-2.amazonaws.com/production"
  // );

  function connectWs() {
    setWs(
      new WebSocket(
        "wss://kw0s9xiywj.execute-api.us-east-2.amazonaws.com/production"
      )
    );
    const customer_phone = JSON.parse(
      localStorage.getItem("access_customer_phone")
    )
      ? JSON.parse(localStorage.getItem("access_customer_phone")).customer_phone
      : "";
    const setUserData = {
      action: "setUser",
      userName: "pogo_" + customer_phone,
    };
    ws.onopen = (event) => {
      ws.send(JSON.stringify(setUserData));
      console.log(setUserData);
    };
  }

  useEffect(() => {
    const customer_phone = JSON.parse(
      localStorage.getItem("access_customer_phone")
    )
      ? JSON.parse(localStorage.getItem("access_customer_phone")).customer_phone
      : "";
    const setUserData = {
      action: "setUser",
      userName: "pogo_" + customer_phone,
    };
    ws.onopen = (event) => {
      ws.send(JSON.stringify(setUserData));
      console.log(setUserData);
    };
  }, []);

  // const userNameOnWS =
  //   "pogo_" + JSON.parse(localStorage.getItem("access_customer_phone"));

  const [messageHistory, setMessageHistory] = useState([]);

  // const { sendMessage, lastMessage, readyState } = useWebSocket(ws);

  const { t } = useTranslation();
  const [colors, setColors] = useState("red");
  const [text, setText] = useState("");
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [price, setPrice] = useState(0);
  const [search, setSearch] = useState("");
  const [sumStatus, setSumStatus] = useState({
    work: "",
    provided: "",
    notProvided: "",
    DeliverOnWay: "",
    MessengerArrived: "",
  });

  // const [dateShippment, setDateShippment] = useState("1");
  var today = new Date(),
    today_date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      today.getDate().toString().padStart(2, "0");
  const [dateShippment, setDateShippment] = useState(today_date);
  // const [dateShippment, setDateShippment] =
  //   (React.useState < Dayjs) | (null > null);
  const [showSingelInfo, setShowSingelInfo] = useState(null);
  const [statusShippment, setStatusShippment] = useState("1");
  const [history_shippments, setHistoty_Shippment] = useState({ data: [] });
  const [history_shippmentsUnfilter, setHistory_shippmentsUnfilter] = useState({
    data: [],
  });
  const [package_deleted, setPackage_deleted] = useState(false);
  const [filterDate, setFilterDate] = useState("");

  const ref = useRef(null);
  const navigate = useNavigate();
  //console.log(t);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const dateShippmentOptions = [
    { value: "1", label: t("DATE_SIPPMENT_HISTORY_1") },
    { value: "2", label: t("DATE_SIPPMENT_HISTORY_2") },
    { value: "3", label: t("DATE_SIPPMENT_HISTORY_3") },
  ];
  const statusShippmentOptions = [
    { value: "0", label: t("LOOKING_FOR_MESSENGER") },
    { value: "1", label: t("DRIVER_ON_WAY") },
    { value: "2", label: t("PROVIDED") },
    { value: "3", label: t("DRIVER_ARRIVED") },
    { value: "4", label: t("AT_WORK") },
    // { value: "4", label: t("NOT_DELIVERED") },
  ];

  const {
    options,
    setOptions,
    validToken,
    setValidToken,
    getDataFromServer,
    setGetDataFromServer,
    checkboxValue,
    setCheckboxValue,
    exitAddress,
    time,
    detailsOfPackage,
    setVal,
    nameAndPhone,
  } = useContext(DataContext);
  const clone_price_min = Object.assign({}, getDataFromServer);
  const max = clone_price_min.price_min * 3;

  let type;
  // useEffect(() => {
  //   console.log("history change");
  // ws.onmessage = function (event) {
  //   const json = JSON.parse(event.data);
  //   console.log(`[message] Data received from server: ${json}`);
  //   console.log(event.data);
  //   try {
  //     if ((json.event = "data")) {
  //       console.log(json.data);
  //     }
  //     if ((json.type = "changeStatus")) {
  //       //history_shippments.map()
  //       const updated_history_shippments = history_shippments.map((obj) => {
  //         // if id equals 2 replace object
  //         if (obj.id === json.id) {
  //           return {
  //             track_id: obj.track_id,
  //             address_id: obj.address_id,
  //             driver_name: obj.driver_name,
  //             driver_phone: obj.driver_phone,
  //             dropoff_time: obj.dropoff_time,
  //             formatted_address: obj.formatted_address,
  //             formatted_address_2: obj.formatted_address_2,
  //             payment_method: obj.payment_method,
  //             pickup_time: obj.pickup_time,
  //             price: obj.price,
  //             status: json.newStatus,
  //           };
  //         }
  //         // otherwise return object as is
  //         return obj;
  //       });
  //       setHistoty_Shippment(updated_history_shippments);
  //       console.log(json.data);
  //     }
  //   } catch (err) {
  //     // whatever you wish to do with the err
  //   }
  // };
  // }, [history_shippments]);

  // useEffect(() => {
  //   lastMessage && setMessageHistory((prev) => prev.concat(lastMessage.data));
  // }, [lastMessage]);
  // const get_history = async () => {
  //   const customer_name = JSON.parse(
  //     localStorage.getItem("access_token_client")
  //   )
  //     ? JSON.parse(localStorage.getItem("access_token_client")).customer_name
  //     : "";
  //   const access_token = JSON.parse(localStorage.getItem("access_token_client"))
  //     ? JSON.parse(localStorage.getItem("access_token_client")).access_token
  //     : "";
  //   const _validToken = {
  //     access_token: access_token,
  //     customer_name: customer_name,
  //   };
  //   try {
  //     if (dateShippment === "1") {
  //       type = "weekly";
  //     }
  //     if (dateShippment === "2") {
  //       type = "monthly";
  //     }
  //     if (dateShippment === "3") {
  //       type = "daily";
  //     }
  //     const result = await axios.get(
  //       `/pogo_connect_api/server/web/index.php/customers/get_deliveries_history?type=${type}`,
  //       {
  //         headers: {
  //           //   Authorization: `Bearer ${validToken?.access_token}`,
  //           Authorization: `Bearer ${_validToken?.access_token}`,
  //           "Access-Control-Allow-Origin": "*",
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     // console.log(result);
  //     if (result.data) {
  //       history_shippments.data = result.data;
  //       setHistoty_Shippment({ ...history_shippments });
  //     }
  //   } catch (error) {
  //     if (error.response.status == 401) {
  //       localStorage.removeItem("access_token_client");
  //       setValidToken(false);
  //     }
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    const get_deliveries_history = async () => {
      try {
        if (dateShippment === "1") {
          type = "weekly";
        }
        if (dateShippment === "2") {
          type = "monthly";
        }
        if (dateShippment === "3") {
          type = "daily";
        }
        // var request_url = `/pogo_connect_api/server/web/index.php/customers/get_deliveries_history?type=${type}`;
        var request_url = `/pogo_connect_api/server/web/index.php/customers/get_deliveries_history?type=daily&date=${dateShippment}`;
        const result = await axios.get(request_url, {
          headers: {
            //   Authorization: `Bearer ${validToken?.access_token}`,
            Authorization: `Bearer ${_validToken?.access_token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
        // console.log(result);
        if (result.data) {
          history_shippments.data = result.data;
          //setHistoty_Shippment({ ...history_shippments });
          // setHistory_shippmentsUnfilter(result.data);
          history_shippmentsUnfilter.data = result.data;
          // setHistory_shippmentsUnfilter({ ...history_shippments });

          localStorage.setItem(
            "histoty_shippment",
            JSON.stringify({
              data: result.data,
            })
          );

          sumStatus.notProvided = result.data.filter(
            (item) => item.status == 0
          ).length;
          sumStatus.provided = result.data.filter(
            (item) => item.status == 2
          ).length;
          sumStatus.work = result.data.filter(
            (item) => item.status == 1
          ).length;
          setSumStatus({ ...sumStatus });
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("access_token_client");
          setValidToken(false);
        }
        console.log(error);
      }
    };

    const customer_name = JSON.parse(
      localStorage.getItem("access_token_client")
    )
      ? JSON.parse(localStorage.getItem("access_token_client")).customer_name
      : "";
    const access_token = JSON.parse(localStorage.getItem("access_token_client"))
      ? JSON.parse(localStorage.getItem("access_token_client")).access_token
      : "";
    const _validToken = {
      access_token: access_token,
      customer_name: customer_name,
    };
    //validToken && get_deliveries_history().catch(console.error);
    _validToken && get_deliveries_history().catch(console.error);
  }, [dateShippment, package_deleted]);
  // }, []);

  ws.onclose = function (e) {
    console.log(
      "Socket is closed. Reconnect will be attempted in 1 second.",
      e.reason
    );
    setTimeout(function () {
      connectWs();
    }, 1000);
  };

  ws.onmessage = function (event) {
    const json = JSON.parse(event.data);
    console.log(`[message] Data received from server: ${json}`);
    console.log(event.data);
    try {
      if ((json.event = "data")) {
        console.log(json.data);
      }
      if ((json.type = "changeStatus")) {
        const updated_history_shippments = history_shippments.data.map(
          (obj) => {
            // if id equals update object
            if (obj.track_id === json.id) {
              return {
                track_id: obj.track_id,
                address_id: obj.address_id,
                driver_name: obj.driver_name,
                driver_phone: obj.driver_phone,
                dropoff_time: obj.dropoff_time,
                formatted_address: obj.formatted_address,
                formatted_address_2: obj.formatted_address_2,
                formatted_address_3: obj.formatted_address_3,
                formatted_address_4: obj.formatted_address_4,
                payment_method: obj.payment_method,
                pickup_time: obj.pickup_time,
                price: obj.price,
                status: json.newStatus,
              };
            }
            // otherwise return object as is
            return obj;
          }
        );
        history_shippments.data = updated_history_shippments;
        // setHistoty_Shippment({ data: updated_history_shippments });
        //setHistoty_Shippment(...history_shippments);
        history_shippmentsUnfilter.data = updated_history_shippments;
        //history_shippmentsUnfilter(...history_shippmentsUnfilter);
        console.log(history_shippments);
      }
      if ((json.type = "newDelivery")) {
        console.log(json);
        const new_delivery = {
          track_id: json.proposal.track_id,
          // address_id: json.proposal.address_id,
          driver_name: json.proposal.driver_name,
          driver_phone: json.proposal.driver_phone,
          dropoff_time: json.proposal.dropoff_time,
          formatted_address: json.proposal.formatted_address,
          formatted_address_2: json.proposal.formatted_address_2,
          formatted_address_3: json.proposal.formatted_address_3,
          formatted_address_4: json.proposal.formatted_address_4,
          payment_method: json.proposal.payment_method,
          pickup_time: json.proposal.pickup_time,
          price: json.proposal.price,
          status: json.proposal.status,
        };
        history_shippments.data = [...history_shippments.data, new_delivery];
        history_shippmentsUnfilter.data = [
          ...history_shippmentsUnfilter.data,
          new_delivery,
        ];
        setHistoty_Shippment(...history_shippments, new_delivery);
        setHistory_shippmentsUnfilter(...history_shippments, new_delivery);
        console.log(history_shippments);
      }
      if ((json.type = "caught")) {
        console.log("caught meesage");
      }
    } catch (err) {}
  };

  const delete_track = async (track_id) => {
    try {
      let popuptext = t("CONFIRM_TRACKING_SHIPMENT_CANCEL") + "?";
      if (window.confirm(popuptext)) {
        console.log("confirm");

        const data = { track_id: track_id };
        const result = await axios.post(
          `/pogo_connect_api/server/web/index.php/tracks/delete_track`,
          data,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        if (result.data) {
          console.log(result);
          setPackage_deleted(!package_deleted);
          // setDateShippment()
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const relase_driver = async (track_id, driver_id) => {
    try {
      let popuptext = t("CONFIRM_REPLACE_MESSENGER") + "?";
      if (window.confirm(popuptext)) {
        console.log("confirm");
        const data = {
          track_id: track_id,
          driver_id: driver_id,
        };
        // const result = await axios.post(
        //   `/pogo_connect_api/server/web/index.php/tracks/relase_driver`,
        //   data,
        //   {
        //     headers: {
        //       "Access-Control-Allow-Origin": "*",
        //       "Content-Type": "application/json",
        //     },
        //   }
        // );
        // if (result.data) {
        //   console.log(result);
        //   setPackage_deleted(!package_deleted);
        //   // setDateShippment()
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(sumStatus);

  const checkboxHandlerTruck = (e) => {
    e.target.checked
      ? (checkboxValue.TruckChecked = "1")
      : (checkboxValue.TruckChecked = "0");
    setCheckboxValue({ ...checkboxValue });
    if (e.target.checked) {
      //   setDetails(t("STEPS_NUM_2_TYPE_PACKAGE_SELECT_3_DETAIL"));
    } else {
      //   setDetails("");
    }
  };

  const checkboxHandlerMotorcycle = (e) => {
    e.target.checked
      ? (checkboxValue.MotorcycleChecked = "1")
      : (checkboxValue.MotorcycleChecked = "0");
    setCheckboxValue({ ...checkboxValue });
    //setIsShownT((current) => !current);
    if (e.target.checked) {
      //   setDetails(t("STEPS_NUM_2_TYPE_PACKAGE_SELECT_1_DETAIL"));
    } else {
      //   setDetails("");
    }
  };

  const checkboxHandlerCar = (e) => {
    e.target.checked
      ? (checkboxValue.CarChecked = "1")
      : (checkboxValue.CarChecked = "0");
    setCheckboxValue({ ...checkboxValue });

    if (e.target.checked) {
      //   setDetails(t("STEPS_NUM_2_TYPE_PACKAGE_SELECT_2_DETAIL"));
    } else {
      //   setDetails("");
    }
    //setIsShownT((current) => !current);
    // setIsShownC((current) => !current);
    // setIsShownM(false);
    // setIsShownT(false);
  };

  const styleLabelCheckBox = (check) => {
    if (check !== "1") {
      return {
        fontFamily: "Heebo",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "17px",
        lineHeight: "25px",
        color: "#81838C",
      };
    } else {
      return {
        fontFamily: "Heebo",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "17px",
        lineHeight: "25px",
        color: "#0D152E",
      };
    }
  };

  const InputLabelProps = {
    textAlign: "right",
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#4F5976",
  };

  const isRTL = (s) => {
    if (s !== "") {
      var ltrChars =
          "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
          "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
        rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
        rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]");

      return rtlDirCheck.test(s);
    }
    return true;
  };

  const themeRtl = createTheme({
    direction: "rtl",
  });

  const themeLtr = createTheme({
    direction: "ltr",
  });

  const cacheLtr = createCache({
    key: "muiltr",
  });

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });

  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });

  const LabelSearch = {
    width: "100%",
    height: "22px",
    textAlign: "right",
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
    // document.documentElement.dir === "ltr"
    //     ? "ltr"
    //     : "rtl",
    lineHeight: "22px",
    color: "#A1A1AC",
  };

  function isNum(status) {
    return /\d/.test(status);
  }
  const filterDetailsByStatus = (status) => {
    if (isNum(status)) {
      setStatusShippment(status);
      switch (status) {
        case "1":
          status = 2;
          break;
        case "2":
          status = 1;
          break;
        case "3":
          status = 0;
          break;
        default:
          break;
      }
    } else {
      switch (status) {
        case "סופק":
          setStatusShippment(2);
          status = 2;
          setActive1(false);
          setActive2(true);
          setActive3(false);
          setActive4(false);
          setActive5(false);
          break;
        case "provided":
          status = 2;
          setActive1(false);
          setActive2(true);
          setActive3(false);
          setActive4(false);
          setActive5(false);
          break;
        case "בעבודה":
          setStatusShippment(4);
          status = 4;
          setActive2(false);
          setActive3(false);
          setActive1(true);
          setActive4(false);
          setActive5(false);
          break;
        case "work":
          status = 4;
          setActive2(false);
          setActive3(false);
          setActive1(true);
          setActive4(false);
          setActive5(false);
          break;
        case "מחפש שליח":
          setStatusShippment(0);
          status = 0;
          setActive3(true);
          setActive2(false);
          setActive1(false);
          setActive4(false);
          setActive5(false);
          break;
        case "looking_for_messenger":
          setStatusShippment(0);
          status = 0;
          setActive3(true);
          setActive2(false);
          setActive1(false);
          setActive4(false);
          setActive5(false);
          break;
        case "DeliverOnWay":
          setStatusShippment(1);
          status = 1;
          setActive5(false);
          setActive4(true);
          setActive3(false);
          setActive2(false);
          setActive1(false);
          break;
        case "השליח בדרך אליך":
          setStatusShippment(1);
          status = 1;
          setActive5(false);
          setActive4(true);
          setActive3(false);
          setActive2(false);
          setActive1(false);
          break;
        case "MessengerArrived":
          setStatusShippment(3);
          status = 3;
          setActive5(true);
          setActive4(false);
          setActive3(false);
          setActive2(false);
          setActive1(false);
          break;
        case "השליח אצלך":
          setStatusShippment(3);
          status = 3;
          setActive5(true);
          setActive4(false);
          setActive3(false);
          setActive2(false);
          setActive1(false);
          break;
        default:
          break;
      }
    }

    const filtered = history_shippmentsUnfilter.data.filter(
      (item) => item.status === status
    );
    // console.log(filtered);
    setHistoty_Shippment({ data: filtered });
  };

  const filterDetailsBySearch = (e) => {
    setSearch(e.target.value);
    // console.log(history_shippments);
    const filtered = history_shippmentsUnfilter.data.filter((item) =>
      item.formatted_address.includes(e.target.value)
    );
    setHistoty_Shippment({ data: filtered });
    // console.log(filtered);
  };

  const backArrow = () => {
    navigate("/connected");
    setOptions("username");
  };

  return (
    <div
      className="background_details_business"
      style={{
        height: isTabletOrMobile && "1240px",
        background: isTabletOrMobile && "none",
      }}
    >
      <div
        className="height_details_business"
        style={{
          gap: !isTabletOrMobile && "15px",
          width: "100%",
          //width: "850px",
          transform: isTabletOrMobile && "none",
          boxShadow: isTabletOrMobile && "none",
        }}
      >
        {!isTabletOrMobile && (
          <div
            className="img_back_arrow"
            style={{ cursor: "pointer" }}
            onClick={backArrow}
          >
            <div className="back_arrow"></div>
          </div>
        )}
        <div
          className="flex_header_details"
          style={{
            height: "70px",
            marginTop: isTabletOrMobile && "0px",
            width: "100%",
          }}
        >
          <h1 className="h1_mobile history_h1" style={{}}>
            {" "}
            {t("HISTORY_SHIPPMENTS")}{" "}
          </h1>{" "}
        </div>{" "}
        <div className="flex_inputs">
          <div
            className="history_width_line1"
            style={{
              flexDirection: isTabletOrMobile && "column",
              height: isTabletOrMobile && "auto",
            }}
          >
            <CacheProvider
              value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
            >
              <ThemeProvider
                theme={ltrTheme.direction === "ltr" ? themeRtl : themeLtr}
              >
                <CssBaseline />
                {/* <div style={{ width: isTabletOrMobile ? "100%" : "58%" }}> */}
                <div style={{ width: isTabletOrMobile ? "100%" : "50%" }}>
                  <TextField
                    style={
                      {
                        // direction: ltrTheme.direction === "ltr"
                        //     ? "ltr"
                        //     : "rtl"
                      }
                    }
                    value={search}
                    onChange={filterDetailsBySearch}
                    InputProps={{
                      endAdornment: !search ? (
                        <InputAdornment
                          // sx={{  }}
                          position="start"
                          //style={{ color: "#000000" }}
                        >
                          <img src={Search} alt="Search" />
                        </InputAdornment>
                      ) : undefined,
                      sx: {
                        "& .MuiInputAdornment-root": {
                          marginLeft: "5px",
                          marginRight: "0px",
                          color: "#000000",
                        },
                      },
                    }}
                    placeholder={t("TYPE_SEARCH")}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        fontFamily: "Heebo",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "15px",
                        lineHeight: "22px",
                        // height:"62px",
                        /* identical to box height */

                        textAlign: "right",

                        color: "#A1A1AC",
                      },

                      "& .MuiOutlinedInput-input": {
                        color: "#000000",

                        direction:
                          ltrTheme.direction === "ltr" && !isRTL(search)
                            ? "rtl"
                            : "ltr",
                      },
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      //  direction: ltrTheme.direction === "rtl"
                      //     ? "ltr"
                      //     : "rtl",
                      marginBottom:
                        isTabletOrMobile && !isMobile ? "15px" : "35px",
                      width: "100%",
                    }}
                    InputLabelProps={{ style: LabelSearch }}
                  ></TextField>
                </div>
              </ThemeProvider>
            </CacheProvider>

            <CacheProvider
              value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
            >
              <ThemeProvider
                theme={ltrTheme.direction === "ltr" ? themeRtl : themeLtr}
              >
                <CssBaseline />
                {/* <div style={{ width: isMobile ? "46%" : "100%" }}> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    showToolbar={false}
                    renderInput={(params) => <TextField {...params} />}
                    label={t("FILTER_BY_DATE")}
                    // onFocus={checkOnFocus}
                    type="datetime-local"
                    InputProps={{
                      style: {
                        //marginLeft: !isTabletOrMobile && "10px",
                        marginBottom: isMobile
                          ? "23px"
                          : isTabletOrMobile && "35px",
                        textAlign:
                          ltrTheme.direction === "ltr" ? "right" : "left",
                        display:
                          ltrTheme.direction === "ltr" ? "flex" : "block",
                        flexDirection:
                          ltrTheme.direction === "ltr" ? "row-reverse" : "row",
                        height: isMobile ? "40px" : isTabletOrMobile && "40px",
                        alignItems:
                          ltrTheme.direction === "rtl" &&
                          isMobile &&
                          "flex-end",
                      },
                      startAdornment: (
                        <InputAdornment
                          style={{ cursor: "pointer" }}
                          position="start"
                        >
                          {/* <CalendarMonthIcon /> */}
                        </InputAdornment>
                      ),
                    }}
                    placeholder="mm/dd/yyyy"
                    value={dateShippment}
                    onChange={(e) => {
                      // var month =
                      //   e["$M"] + 1 < 10 ? "0" + (e["$M"] + 1) : e["$M"] + 1;
                      // var day =
                      //   e["$D"] + 1 < 10 ? "0" + (e["$D"] + 1) : e["$D"] + 1;
                      // var date = e["$y"] + "-" + month + "-" + day;
                      var date =
                        e["$y"] +
                        "-" +
                        (e["$M"] + 1).toString().padStart(2, "0") +
                        "-" +
                        e["$D"].toString().padStart(2, "0");

                      setDateShippment(date);
                    }}
                    style={{
                      "& .muirtl-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root":
                        {
                          // width: "50%",
                          margin: "50px",
                          marginBottom: isMobile
                            ? "23px"
                            : isTabletOrMobile && "35px",
                        },
                      "& .muirtl-1u3bzj6-MuiFormControl-root-MuiTextField-root":
                        {
                          maxWidth: "initial",
                        },
                    }}
                    // sx={{
                    //   "& .MuiOutlinedInput-root": {
                    //     margin: "50px",
                    //     marginBottom: isMobile
                    //       ? "23px"
                    //       : isTabletOrMobile && "35px",
                    //   },
                    //   "& .MuiFormHelperText-root": {
                    //     fontFamily: "heebo",
                    //   },
                    //   width: isMobile
                    //     ? 300
                    //     : isTabletOrMobile
                    //     ? "100%"
                    //     : "48%",
                    //   marginBottom: isMobile
                    //     ? "23px"
                    //     : isTabletOrMobile && "35px",
                    //   "& fieldset": {
                    //     direction:
                    //       ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                    //   },
                    // }}
                    InputLabelProps={{
                      style: InputLabelProps,
                      shrink: true,
                    }}
                  />
                </LocalizationProvider>
                {/* </div> */}
                {/* <TextField
                        style={{
                          direction:
                            ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                          borderWidth: "0px",
                          // width: 97px;
                          // height: '62px',

                          fontFamily: "Heebo",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "15px",
                          lineHeight: "22px",

                          textAlign: "right",

                          color: "#0D152E",
                        }}
                        id="outlined-select-currency"
                        select
                        SelectProps={{
                          IconComponent: () => (
                            <img
                              src={ArrowDown}
                              alt="arrow_down"
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                            />
                          ),
                        }}
                        value={dateShippment}
                        onChange={(e) => {
                          setDateShippment(e.target.value);
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderWidth: "0px",
                            // width: 97px;
                            // height: '62px',

                            fontFamily: "Heebo",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "15px",
                            lineHeight: "22px",

                            // textAlign: 'right',

                            color: "#0D152E",
                          },

                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          width: !isMobile && "100%",
                          marginBottom: isTabletOrMobile && !isMobile && "18px",

                          // maxWidth: "545px", width: "100%",
                          // "& .MuiOutlinedInput-root": { height: "71px" }
                        }}
                      >
                        {dateShippmentOptions.map((option) => (
                          <MenuItem
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField> */}
                {/* </div> */}
              </ThemeProvider>
            </CacheProvider>

            {isMobile && (
              <CacheProvider
                value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
              >
                <ThemeProvider
                  theme={ltrTheme.direction === "ltr" ? themeRtl : themeLtr}
                >
                  <CssBaseline />
                  <div
                    style={{
                      display: !isTabletOrMobile && "none",
                      width: isMobile && "100%",
                    }}
                  >
                    <TextField
                      style={{
                        direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                      }}
                      id="outlined-select-currency"
                      select
                      SelectProps={{
                        IconComponent: () => (
                          <img
                            src={ArrowDown}
                            alt="arrow_down"
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                          />
                        ),
                      }}
                      value={statusShippment}
                      onChange={(e) => {
                        filterDetailsByStatus(
                          statusShippmentOptions[e.target.value].label
                        );
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderWidth: "0px",
                          // width: 97px;
                          // height: '62px',
                          fontFamily: "Heebo",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "15px",
                          lineHeight: "22px",
                          // textAlign: 'right',
                          color: "#0D152E",
                        },
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        width: "100%",
                        // width: !isTabletOrMobile && "40%"
                        // maxWidth: "545px", width: "100%",
                        // "& .MuiOutlinedInput-root": { height: "71px" }
                      }}
                      //   helperText="Please select your currency"
                    >
                      {statusShippmentOptions.map((option) => (
                        <MenuItem
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </ThemeProvider>
              </CacheProvider>
            )}
          </div>

          <div
            className="content_history_shipments"
            // style={{height:isTabletOrMobile && "120px"}}
          >
            {!isTabletOrMobile && (
              <div className="head_history_content">
                <span
                  className={"p_label"}
                  onClick={() => {
                    filterDetailsByStatus("looking_for_messenger");
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#0D152E",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  {t("LOOKING_FOR_MESSENGER")}{" "}
                  {sumStatus.notProvided > 0
                    ? `(${sumStatus.notProvided})`
                    : ""}
                  <span className={active3 ? "active" : ""}></span>
                </span>
                <span
                  className={"p_label"}
                  onClick={() => {
                    filterDetailsByStatus("work");
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#0D152E",
                    cursor: "pointer",
                  }}
                >
                  {t("AT_WORK")}{" "}
                  {sumStatus.work > 0 ? `(${sumStatus.work})` : ""}
                  <span className={active1 ? "active" : ""}></span>
                </span>
                <span
                  className={"p_label"}
                  onClick={() => {
                    filterDetailsByStatus("provided");
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#0D152E",
                    cursor: "pointer",
                  }}
                >
                  {t("PROVIDED")}{" "}
                  {sumStatus.provided > 0 ? `(${sumStatus.provided})` : ""}
                  <span className={active2 ? "active" : ""}></span>
                </span>
                <span
                  className={"p_label"}
                  onClick={() => {
                    filterDetailsByStatus("DeliverOnWay");
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#0D152E",
                    cursor: "pointer",
                  }}
                >
                  {t("DRIVER_ON_WAY")}{" "}
                  {sumStatus.DeliverOnWay > 0
                    ? `(${sumStatus.DeliverOnWay})`
                    : ""}
                  <span className={active4 ? "active" : ""}></span>
                </span>
                <span
                  className={"p_label"}
                  onClick={() => {
                    filterDetailsByStatus("MessengerArrived");
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#0D152E",
                    cursor: "pointer",
                  }}
                >
                  {t("DRIVER_ARRIVED")}{" "}
                  {sumStatus.MessengerArrived > 0
                    ? `(${sumStatus.MessengerArrived})`
                    : ""}
                  <span className={active5 ? "active" : ""}></span>
                </span>
              </div>
            )}

            <div
              className="data_delivery"
              style={{
                width: "650px",
                overflow: isTabletOrMobile && "hidden",
              }}
            >
              {history_shippments &&
                history_shippments.data.map((item, index) => {
                  return (
                    <div
                      style={{
                        marginBottom:
                          isTabletOrMobile &&
                          showSingelInfo === index &&
                          "320px",
                        boxShadow:
                          isTabletOrMobile &&
                          showSingelInfo !== index &&
                          "none",
                      }}
                      key={index}
                      id={index}
                      className={
                        isTabletOrMobile
                          ? "div_data_delivery_mobile"
                          : "div_data_delivery"
                      }
                    >
                      <div
                        className={
                          isTabletOrMobile
                            ? "info_delivery_line1_mobile"
                            : "info_delivery_line1"
                        }
                      >
                        <div
                          style={{
                            maxWidth: "550px",
                            width: "100%",
                            display: isTabletOrMobile && "flex",
                            flexDirection: "row-reverse",
                          }}
                        >
                          <div
                            style={{
                              width: isTabletOrMobile && "75%",
                              display: "flex",
                              alignItems: isTabletOrMobile && "flex-end",
                              // flexDirection: isTabletOrMobile
                              //   ? "column-reverse"
                              //   : "row-reverse",
                              flexDirection: "row-reverse",
                            }}
                            className={
                              !isTabletOrMobile && "info_delivery_line1"
                            }
                          >
                            <div
                              className={
                                isTabletOrMobile
                                  ? "flex_address_shipment_mobile"
                                  : "flex_address_shipment"
                              }
                              style={{
                                maxWidth: "70%",
                                // maxWidth: "75%",
                                width: !isTabletOrMobile && "90%",
                                height: isTabletOrMobile && "55px",
                                marginRight: isTabletOrMobile && "5px",
                              }}
                            >
                              {!isTabletOrMobile && (
                                <label
                                  className={
                                    isTabletOrMobile
                                      ? "address_shipment_mobile"
                                      : "address_shipment"
                                  }
                                >
                                  {t("SHIPPING_ADDRESS")}
                                </label>
                              )}

                              <label
                                style={{
                                  overflow:
                                    item?.formatted_address.length > 30 &&
                                    "hidden",
                                  fontSize:
                                    item?.formatted_address.length > 15
                                      ? "12px!important"
                                      : "14px",
                                }}
                                className="info_delivery_server delivery_server_line1"
                              >
                                {item?.formatted_address.substring(0, 15)}
                                {item?.formatted_address.length > 15 && "..."}
                              </label>
                            </div>{" "}
                            {item?.formatted_address_2 && (
                              <div
                                className={
                                  isTabletOrMobile
                                    ? "flex_address_shipment_mobile"
                                    : "flex_address_shipment"
                                }
                                style={{
                                  maxWidth: "70%",
                                  // maxWidth: "75%",
                                  width: !isTabletOrMobile && "90%",
                                  height: isTabletOrMobile && "55px",
                                  marginRight: isTabletOrMobile && "5px",
                                }}
                              >
                                {!isTabletOrMobile && (
                                  <label
                                    className={
                                      isTabletOrMobile
                                        ? "address_shipment_mobile"
                                        : "address_shipment"
                                    }
                                  >
                                    כתובת נוספת
                                  </label>
                                )}

                                <label
                                  style={{
                                    overflow:
                                      item?.formatted_address_2.length > 30 &&
                                      "hidden",
                                    fontSize:
                                      item?.formatted_address.length > 15
                                        ? "12px!important"
                                        : "14px",
                                  }}
                                  className="info_delivery_server delivery_server_line1"
                                >
                                  {item?.formatted_address_2.substring(0, 15)}
                                  {item?.formatted_address_2.length > 15 &&
                                    "..."}
                                </label>
                              </div>
                            )}
                            {item?.formatted_address_3 && (
                              <div
                                className={
                                  isTabletOrMobile
                                    ? "flex_address_shipment_mobile"
                                    : "flex_address_shipment"
                                }
                                style={{
                                  maxWidth: "70%",
                                  // maxWidth: "75%",
                                  width: !isTabletOrMobile && "90%",
                                  height: isTabletOrMobile && "55px",
                                  marginRight: isTabletOrMobile && "5px",
                                }}
                              >
                                {!isTabletOrMobile && (
                                  <label
                                    className={
                                      isTabletOrMobile
                                        ? "address_shipment_mobile"
                                        : "address_shipment"
                                    }
                                  >
                                    כתובת נוספת
                                  </label>
                                )}

                                <label
                                  style={{
                                    overflow:
                                      item?.formatted_address_3.length > 30 &&
                                      "hidden",
                                  }}
                                  className="info_delivery_server delivery_server_line1"
                                >
                                  {item?.formatted_address_3.substring(0, 15)}
                                  {item?.formatted_address_3.length > 15 &&
                                    "..."}
                                </label>
                              </div>
                            )}
                            {item?.formatted_address_4 && (
                              <div
                                className={
                                  isTabletOrMobile
                                    ? "flex_address_shipment_mobile"
                                    : "flex_address_shipment"
                                }
                                style={{
                                  maxWidth: "70%",
                                  // maxWidth: "75%",
                                  width: !isTabletOrMobile && "90%",
                                  height: isTabletOrMobile && "55px",
                                  marginRight: isTabletOrMobile && "5px",
                                }}
                              >
                                {!isTabletOrMobile && (
                                  <label
                                    className={
                                      isTabletOrMobile
                                        ? "address_shipment_mobile"
                                        : "address_shipment"
                                    }
                                  >
                                    כתובת נוספת
                                  </label>
                                )}

                                <label
                                  style={{
                                    overflow:
                                      item?.formatted_address_4.length > 30 &&
                                      "hidden",
                                  }}
                                  className="info_delivery_server delivery_server_line1"
                                >
                                  {item?.formatted_address_4.substring(0, 15)}
                                  {item?.formatted_address_4.length > 15 &&
                                    "..."}
                                </label>
                              </div>
                            )}
                            {!isTabletOrMobile && (
                              <div
                                className={
                                  isTabletOrMobile
                                    ? "status_shipping"
                                    : "status_shipping"
                                }
                              >
                                <label
                                  className="p_label"
                                  style={{
                                    textAlign: "center",
                                    color: "#0D152E",
                                  }}
                                >
                                  {item.status === 0 &&
                                    t("LOOKING_FOR_MESSENGER")}
                                  {item.status === 1 && t("DRIVER_ON_WAY")}
                                  {item.status === 2 && t("PROVIDED")}
                                  {item.status === 3 && t("DRIVER_ARRIVED")}
                                  {item.status === 4 && t("AT_WORK")}
                                </label>

                                <span className="point" />
                                {/* <label
                                className="p_label"
                                style={{
                                  textAlign: "center",
                                  color: "#0D152E",
                                }}
                              >
                                {item.status == 0 &&
                                  t("TRACKING_SHIPMENT_CHANGE_DRIVER")}
                              </label> */}
                              </div>
                            )}
                          </div>
                          {isTabletOrMobile && (
                            <div
                              style={{
                                marginTop: "14px",
                                width: "90%",
                                cursor: "pointer",
                                marginLeft: "15px",
                              }}
                              onClick={() =>
                                setShowSingelInfo((showSingelInfo) =>
                                  showSingelInfo === index ? null : index
                                )
                              }
                            >
                              <img
                                src={ArrowDown}
                                alt="arrow_down"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {!isTabletOrMobile && (
                        <span className="line_after_address" />
                      )}

                      <div
                        style={{
                          width: !isTabletOrMobile && "100%",
                          visibility: !isTabletOrMobile
                            ? "visible"
                            : showSingelInfo !== index && "hidden",
                        }}
                        className={
                          isTabletOrMobile ? "box_info_data_mobile" : ""
                          // isTabletOrMobile && "box_info_data_mobile"
                        }
                      >
                        <div
                          style={{ height: !isTabletOrMobile && "67px" }}
                          className={
                            isTabletOrMobile
                              ? "info_delivery_line1_mobile line_courier"
                              : "info_delivery_line1"
                          }
                        >
                          <div
                            style={{
                              width: !isTabletOrMobile && "17%",
                              display: isTabletOrMobile && "flex",
                              flexDirection: "column",
                              height: isTabletOrMobile && "60px",
                            }}
                          >
                            <label
                              className={
                                isTabletOrMobile
                                  ? "time_delivery_mobile"
                                  : "time_delivery"
                              }
                            >
                              {t("PICK_UP_TIME")}
                            </label>
                            <label
                              className={
                                isTabletOrMobile
                                  ? "info_delivery_server_mobile"
                                  : "info_delivery_server"
                              }
                            >
                              {item.pickup_time == null
                                ? t("LOOKING_FOR_MESSENGER")
                                : item.pickup_time.split(" ")[1].slice(0, -3)}
                            </label>
                          </div>
                          <div
                            style={{
                              width: !isTabletOrMobile && "15%",
                              display: isTabletOrMobile && "flex",
                              flexDirection: "column",
                              height: isTabletOrMobile && "60px",
                            }}
                          >
                            <label
                              className={
                                isTabletOrMobile
                                  ? "time_delivery_mobile"
                                  : "time_delivery"
                              }
                            >
                              {t("DELIVERY_TIME")}
                            </label>
                            <label
                              className={
                                isTabletOrMobile
                                  ? "info_delivery_server_mobile"
                                  : "info_delivery_server"
                              }
                            >
                              {item.dropoff_time == null
                                ? t("AT_WORK")
                                : item.dropoff_time.split(" ")[1].slice(0, -3)}
                            </label>
                          </div>
                          <div
                            style={{
                              width: !isTabletOrMobile && "11%",
                              display: isTabletOrMobile && "flex",
                              flexDirection: "column",
                              height: isTabletOrMobile && "60px",
                            }}
                          >
                            <label
                              className={
                                isTabletOrMobile
                                  ? "time_delivery_mobile"
                                  : "time_delivery"
                              }
                            >
                              {t("PAYMENT")}
                            </label>
                            <label
                              className={
                                isTabletOrMobile
                                  ? "info_delivery_server_mobile"
                                  : "info_delivery_server"
                              }
                            >
                              {item?.payment_method == 1
                                ? t("CREDIT_CARD")
                                : t("CASH")}
                            </label>
                          </div>
                          <div
                            style={{
                              display: isTabletOrMobile && "flex",
                              flexDirection: "column",
                              height: isTabletOrMobile && "60px",
                            }}
                          >
                            <label
                              className={
                                isTabletOrMobile
                                  ? "time_delivery_mobile"
                                  : "time_delivery"
                              }
                            >
                              {t("SHIPPING_AMOUNT")}
                            </label>
                            <label
                              className={
                                isTabletOrMobile
                                  ? "info_delivery_server_mobile"
                                  : "info_delivery_server"
                              }
                            >
                              {Math.round(item?.price)}
                            </label>
                          </div>
                        </div>

                        <div
                          className={
                            isTabletOrMobile
                              ? "info_delivery_line1_mobile "
                              : "info_delivery_line1"
                          }
                        >
                          <div
                            style={{
                              display: isTabletOrMobile && "flex",
                              flexDirection: "column",
                              height: isTabletOrMobile && "60px",
                            }}
                          >
                            <label
                              className={
                                isTabletOrMobile
                                  ? "time_delivery_mobile"
                                  : "time_delivery"
                              }
                            >
                              תאריך איסוף
                            </label>
                            <label
                              className={
                                isTabletOrMobile
                                  ? "info_delivery_server_mobile"
                                  : "info_delivery_server"
                              }
                            >
                              {item.pickup_time == null
                                ? t("LOOKING_FOR_MESSENGER")
                                : item.pickup_time.split(" ")[0]}
                            </label>
                          </div>
                          <div
                            style={{
                              display: isTabletOrMobile && "flex",
                              flexDirection: "column",
                              height: isTabletOrMobile && "60px",
                            }}
                          >
                            <label
                              className={
                                isTabletOrMobile
                                  ? "time_delivery_mobile"
                                  : "time_delivery"
                              }
                            >
                              תאריך מסירה
                            </label>
                            <label
                              className={
                                isTabletOrMobile
                                  ? "info_delivery_server_mobile"
                                  : "info_delivery_server"
                              }
                            >
                              {item.dropoff_time == null
                                ? t("AT_WORK")
                                : item.dropoff_time.split(" ")[0]}
                            </label>
                          </div>
                          {
                            <div
                              style={{
                                visibility: item.status == 0 && "hidden",
                              }}
                            >
                              <label
                                className={
                                  isTabletOrMobile
                                    ? "time_delivery_mobile"
                                    : "time_delivery"
                                }
                              >
                                {/* {t("SHIPPING_AMOUNT")} */}
                                {t("COURIER_DETAILS")}
                              </label>
                              <label
                                className={
                                  isTabletOrMobile
                                    ? "info_delivery_server_mobile"
                                    : "info_delivery_server"
                                }
                              >
                                {item.driver_phone !== null &&
                                  item.driver_phone}{" "}
                                :{item.driver_name !== null && item.driver_name}{" "}
                              </label>
                            </div>
                          }

                          {/* {item.status === 0 && ( */}
                          {item.status === 0 && (
                            <div
                              onClick={() => delete_track(item.track_id)}
                              style={{ position: "relative" }}
                            >
                              <a
                                style={{ color: "#212529" }}
                                className="false delete_icon"
                              >
                                {t("DELETE")}
                              </a>{" "}
                            </div>
                          )}

                          {/*  החלף שליח כרגע בהערה */}
                          {/* <a
                            //className="p_label"
                            style={{
                              textAlign: "center",
                              color: "#0D152E",
                            }}
                          >
                            {t("TRACKING_SHIPMENT_CHANGE_DRIVER")}
                          </a> */}

                          {/* )} */}
                          {/* <div
                            className={
                              isTabletOrMobile
                                ? "status_shipping"
                                : "status_shipping"
                            }
                          >
                            <a
                              className="p_label"
                              style={{
                                textAlign: "center",
                                color: "#0D152E",
                              }}
                            >
                              {item.status == 0 &&
                                t("TRACKING_SHIPMENT_CHANGE_DRIVER")}
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
