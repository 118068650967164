import {
  TextField,
  CssBaseline,
  autocompleteClasses,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState, useRef, useContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useMediaQuery } from "react-responsive";
import Button from "@mui/material/Button";
import placeholder from "../images/placeholder.png";
import "../detailsPackage/reactAutoComplete.css";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useTranslation } from "react-i18next";
import { height } from "@mui/system";

import { useLocation, useParams } from "react-router-dom";
import { DataContext } from "../../../dataContext";
import { isDisabled } from "@testing-library/user-event/dist/utils";

export default function LocationSearchInput({
  exitAddress,
  setExitaddress,
  // errorAutoComplet,
  // setErrorAutoComplet,
  changeAddressBusinnes,

  onChange,
  value,
  disabled,
  setIsClickd,
  label,
  selected,
  detailsOfPackage,
  handleChangeAddressCollection,
  lng_lat,
  setExitAddressBusinnes,
  exitAddressBusinnes,
  setExtraExitAddressBusinnes,
  setThirdAddressBusinnes,
  setFourthAddressBusinnes,

  //blurHandler,
  setValidBusinnesCity,
  setSelect_address_businnes,
}) {
  const {
    errorAutoComplet,
    setErrorAutoComplet,
    ref,
    businessCostumerConnect,
    showSteps,
    setInvalidAddresspopUpMessage,
    //setExitaddress,
  } = useContext(DataContext);
  const { token } = useParams();
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const [color, setColor] = useState("");
  useEffect(() => {
    if (token) {
      onChange &&
        onChange(
          `${exitAddress?.streetName ? exitAddress?.streetName : ""} ${
            exitAddress?.streetNum ? exitAddress?.streetNum : ""
          } ${exitAddress?.cityName ? exitAddress?.cityName : ""}`
        );
    }
  }, []);

  const { t, i18n } = useTranslation();

  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  // const blurHandler = (e) => {
  //   if (e.includes("ראש העיו")) {
  //     alert("לא ראש העין");
  //   } else {
  //     alert(" ראש העין");
  //   }
  // };
  const handleChange = (address) => {
    // console.log(address);
    onChange && onChange(address);
    handleChangeAddressCollection && handleChangeAddressCollection(address);
    changeAddressBusinnes && changeAddressBusinnes(address);
  };

  const handleSelect = async (address) => {
    try {
      /** BUSINNES ADDRESS ONLY IN ראש העין**/
      // if (  !address.includes("העין") &&
      //   window.location.pathname === "/details"
      // ) {
      //   // setExitAddressBusinnes("");
      //   // setExitaddress({ cityName: "", streetName: "", streetNum: "" });

      //   setInvalidAddresspopUpMessage(true);
      //   //setExitaddress({ cityName: "", streetName: "", streetNum: "" });
      //   //setSelect_address_businnes(false);
      //   setSelect_address_businnes(false);
      //   //changeAddressBusinnes("");
      //   setExitAddressBusinnes("");
      //   handleChangeAddressCollection("");
      //   ref.current.value = "";
      // } else {
      const results = await geocodeByAddress(address);

      const addressComponents = results[0].address_components;
      handleChangeAddressCollection && handleChangeAddressCollection(address);
      changeAddressBusinnes && changeAddressBusinnes(address);
      setSelect_address_businnes && setSelect_address_businnes(true);

      if (addressComponents) {
        onChange && onChange(address);
        setColor("success");
      }
      const filterCity = addressComponents.filter((address_component) =>
        address_component.types.includes("locality")
      );

      const filterStreet = addressComponents.filter((address_component) =>
        address_component.types.includes("route")
      );
      const filterStreetNum = addressComponents.filter((address_component) =>
        address_component.types.includes("street_number")
      );

      const latLng = await getLatLng(results[0]);
      lng_lat && lng_lat(latLng);
      setIsClickd && setIsClickd(true);
      if (setExitaddress) {
        exitAddress.cityName = filterCity[0]?.long_name;
        exitAddress.streetName = filterStreet[0]?.long_name;
        exitAddress.streetNum = filterStreetNum[0]?.long_name;
        exitAddress.lat = latLng.lat;
        exitAddress.lng = latLng.lng;
        setExitaddress({ ...exitAddress });
      }

      if (setExitAddressBusinnes && setSelect_address_businnes) {
        setExitAddressBusinnes([
          //...exitAddressBusinnes,
          {
            cityName: filterCity[0]?.long_name,
            streetName: filterStreet[0]?.long_name,
            streetNum: filterStreetNum[0]?.long_name,
            lat: latLng.lat,
            lng: latLng.lng,
          },
          // ...exitAddressBusinnes,
        ]);
      }

      if (setExtraExitAddressBusinnes && setSelect_address_businnes) {
        setExtraExitAddressBusinnes(
          //...
          [
            //...exitAddressBusinnes,
            {
              cityName: filterCity[0]?.long_name,
              streetName: filterStreet[0]?.long_name,
              streetNum: filterStreetNum[0]?.long_name,
              lat: latLng.lat,
              lng: latLng.lng,
            },
            // ...exitAddressBusinnes,
          ]
        );
      }
      if (setThirdAddressBusinnes && setSelect_address_businnes) {
        setThirdAddressBusinnes([
          {
            cityName: filterCity[0]?.long_name,
            streetName: filterStreet[0]?.long_name,
            streetNum: filterStreetNum[0]?.long_name,
            lat: latLng.lat,
            lng: latLng.lng,
          },
        ]);
      }
      if (setFourthAddressBusinnes && setSelect_address_businnes) {
        setFourthAddressBusinnes([
          {
            cityName: filterCity[0]?.long_name,
            streetName: filterStreet[0]?.long_name,
            streetNum: filterStreetNum[0]?.long_name,
            lat: latLng.lat,
            lng: latLng.lng,
          },
        ]);
      }
      // setExitAddressBusinnes && onChange('')
      selected &&
        selected([
          ...detailsOfPackage,
          {
            id_package: detailsOfPackage.length + 1,
            lat: latLng.lat,
            lng: latLng.lng,
            destination: address,
            cityName: filterCity[0]?.long_name,
            streetName: filterStreet[0]?.long_name,
            streetNum: filterStreetNum[0]?.long_name,
            contactName: "",
            contactPhone: "",
            payForStation: "",
            comment: "",
            currency_symbol_payForStation: "₪",
          },
        ]);
      selected && onChange("");
      //}
    } catch (error) {
      console.error("Error", error);

      return setErrorAutoComplet(t("ERROR_MESSAGE_AUTOCOMPLET"));
    }
  };

  const handleCloseClick = (event) => {
    event.preventDefault();
    onChange("");
  };

  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });

  const InputLabelProps = {
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "right",
    color: "#4F5976",
    direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
  };

  const isRTL = (s) => {
    if (s !== "") {
      var ltrChars =
          "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
          "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
        rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
        rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]");

      return rtlDirCheck.test(s);
    }
    return true;
  };

  const hightResponsiveAutoComplet = () => {
    if (isMobile && !selected) {
      return "40px";
    }
    if (isMobile && selected) {
      return "25px";
    }

    if (isTabletOrMobile && !selected) {
      return "40px";
    }
    if (isTabletOrMobile && selected) {
      return "30px";
    }
  };

  const marginTopAutoComplet = () => {
    if (lng_lat) {
      return "0px";
    }
    if (!selected && isMobile) {
      return "35px";
    }

    if (!selected && !isTabletOrMobile) {
      return "25px";
    }
    if (!selected && isTabletOrMobile) {
      return "35px";
    }
    if (selected && isTabletOrMobile) {
      return "35px";
    }
    if (selected && !isTabletOrMobile) {
      return "30px";
    }
  };

  const switchPaddingLeftlabel = () => {
    if (!selected && !isMobile && ltrTheme.direction === "ltr") {
      return "5px";
    }
    if (isMobile) {
      return "5px";
    }
  };

  const clearAddress = () => {
    if (onChange && !selected) {
      onChange("");
      //setExitaddress({ cityName: "", streetName: "", streetNum: "" });
    }
    if (onChange && selected) {
      onChange("");
    }
    if (handleChangeAddressCollection) {
      handleChangeAddressCollection("");
    }
    if (changeAddressBusinnes) {
      changeAddressBusinnes("");
    }
    if (setExitAddressBusinnes) {
      setExitAddressBusinnes("");
    }

    if (setSelect_address_businnes) {
      setSelect_address_businnes(false);
    }
  };

  return (
    <PlacesAutocomplete
      value={value}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: "relative" }}>
          <CacheProvider
            value={
              ltrTheme.direction === "ltr" && isRTL(value) ? cacheRtl : cacheLtr
            }
          >
            <CssBaseline />
            <TextField
              fullWidth
              inputRef={ref}
              type="text"
              error={!!errorAutoComplet}
              helperText={errorAutoComplet ? errorAutoComplet : ""}
              onFocus={() => setErrorAutoComplet(false)}
              color={color}
              //autoFocus={handleChangeAddressCollection ? false : true}
              //onBlur={blurHandler}
              sx={{
                height: handleChangeAddressCollection && "81px",
                "& legend": {
                  display: location.pathname == "/connected" && "none",
                },
                "& fieldset": { top: location.pathname !== "/connected" && 0 },
                "& .MuiFormHelperText-root": { fontFamily: "heebo" },
                "& .MuiOutlinedInput-root": {
                  height: handleChangeAddressCollection && "71px",

                  fontFamily: "Heebo",
                  fontSize: "16px",
                  lineHeight: "25px",
                  direction:
                    ltrTheme.direction === "ltr" && isRTL(value)
                      ? "rtl"
                      : "rtl",
                },
                "& fieldset": {
                  direction:
                    ltrTheme.direction === "rtl" && isRTL(value)
                      ? "ltr"
                      : "ltr",
                  "& legend": {
                    "& span": { paddingLeft: switchPaddingLeftlabel() },
                  },
                },
              }}
              style={{ width: "100%", maxWidth: "545px" }}
              label={!changeAddressBusinnes && label}
              id="outlined-size-small"
              //  inputProps={{direction: "rtl"}}
              inputProps={{
                style: {
                  direction:
                    ltrTheme.direction === "ltr" && isRTL(value)
                      ? "rtl"
                      : "ltr",
                  height: hightResponsiveAutoComplet(),
                },
              }}
              InputProps={{
                startAdornment: value ? (
                  <InputAdornment position="start">
                    <IconButton size="small" onClick={clearAddress}>
                      <ClearIcon style={{ color: "#583DFF" }} />
                    </IconButton>
                  </InputAdornment>
                ) : undefined,
                style: {
                  fontFamily: handleChangeAddressCollection && "Heebo",
                  fontStyle: handleChangeAddressCollection && "normal",
                  fontWeight: handleChangeAddressCollection && "500",
                  color: handleChangeAddressCollection && "#0D152E",
                },
              }}
              InputLabelProps={{
                style: InputLabelProps,
                shrink: true,
              }}
              {...getInputProps({
                style: {
                  fontFamily: "Heebo",
                },
                placeholder: label,
                className: "location-search-input",
              })}
            />
            <div
              style={{
                fontWeight: "bold",
                fontFamily: "Heebo",
                marginTop: marginTopAutoComplet(),
                border: suggestions.length ? "1px solid black" : "",
                borderTop: suggestions.length ? "0px" : "",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                borderRadius: "0 0 2px 2px",
                zIndex: "1000",
                maxWidth: isTabletOrMobile ? "516px" : "545px",
                direction: !isRTL(value) ? "ltr" : "rtl",
                position: "absolute",
                width: "100%",
                letterSpacing: "0",
              }}
              className="autocomplete-dropdown-container"
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, i) => {
                // const className = suggestion.active
                //   ? 'suggestion-item--active'
                //   : 'suggestion-item';
                const style = suggestion.active
                  ? {
                      backgroundColor: "#F1F1F6",
                      cursor: "pointer",
                      color: "#583DFF",
                      fontFamily: "Heebo",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      fontFamily: "Heebo",
                    };
                return (
                  <div
                    key={i}
                    style={
                      {
                        // fontFamily: "Heebo"
                        // , width: '100%',
                        // height: '20px',
                        // backgroundColor: 'white',
                        // borderBottom: '1px black dotted',
                        // display: 'flex',
                        // alignItems: 'center',
                        // fontSize: '1rem',
                        // fontWeight: 'bold',
                        // color: 'black'
                      }
                    }
                    className="input-suggestion"
                    {...getSuggestionItemProps(suggestion, {
                      // className,
                      style,
                    })}
                    // onClick={handleClick}
                  >
                    <img
                      style={{ margin: "8px" }}
                      height={20}
                      width={20}
                      src={placeholder}
                    />{" "}
                    <span style={{ fontFamily: "Heebo" }} className="dirInput">
                      {suggestion.description}{" "}
                    </span>
                  </div>
                );
              })}
            </div>
          </CacheProvider>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
