import * as React from "react";
import "../detailsPackage/MultiSteps/stepsNum/stepsNumCss/popUp.css";
import {
  Typography,
  TextField,
  Alert,
  AlertTitle,
  CssBaseline,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

import LoadingButton from "@mui/lab/LoadingButton";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  CardElement,
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import _ from "lodash";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";

import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import { useMediaQuery } from "react-responsive";
import createCache from "@emotion/cache";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
import parsePhoneNumber from "libphonenumber-js";
import { usePaymentInputs } from "react-payment-inputs";
import LocationSearchInput from "../detailsPackage/reactAutoComplete";
import "../detailsPackage/reactAutoComplete.css";
import "./details_business.css";
import StripeContainer from "../login/payment/elements";
import PaymentForm from "../login/payment/paymentForm";
// import PopUpMoreDetails from "./popUpDetails_responsive"
import styled from "@emotion/styled";
import { t } from "i18next";
import axios from "axios";
// import DetailsOfPackage from '../../detailsOfPackage';
import { type } from "@testing-library/user-event/dist/type";
import { set } from "lodash";
import { useState } from "react";
import { useContext } from "react";
import { DataContext } from "../../../dataContext";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorAlert from "./errorMessage";
import { updateServer } from "../detailsPackage/MultiSteps/stepsNum/updateServer";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Modal from "@mui/material/Modal";

export default function ContentDetailsOfBusiness({ open, setOpen }) {
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();
  const token = JSON.parse(localStorage.getItem("access_token_client"));
  const [select_address_businnes, setSelect_address_businnes] = useState(false);

  const [errorBusinnesDetails, setErrorBusinnesDetails] = useState(false);
  const {
    update_credit_card,
    setUpdate_credit_card,
    setValidToken,
    validToken,
    client_secret,
    getDataFromServer,
    setGetDataFromServer,
    detailsOfPackage,
    exitAddress,
    setClientSecret,
    sumitCusId,
    setSumitCusId,
    allDetailsofBusinnes,
    setAllDetailsofBusinnes,
    setErrorsPhoneName,
    errorsPhoneName,
    paymentMethodId,
    setPaymentMethodId,
    idCreditCard,
    setIdCreditCard,
    errorsCreditCard,
    setErrorsCreditCard,
    exitAddressBusinnes,
    setExitAddressBusinnes,
    invalidAddresspopUpMessage,
    setInvalidAddresspopUpMessage,
    setOptions,
    phone_connect,
    moveToDetailsOfBusiness,
    setMoveToDetailsOfBusiness,
    setIsUpdateServer,
    time
  } = useContext(DataContext);
  const [errorMail, setErrorMail] = useState("");
  const [lng_lat, setLng_lat] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cloneResultData, setCloneResultData] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (location.pathname == "/details" && validToken)
      errorsCreditCard.empty = false;
    setErrorsCreditCard({ ...errorsCreditCard });
  }, []);

  

  // console.log(errorsCreditCard)
  // const stripe = useStripe()
  // const elements = useElements()
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(lng_lat);
  const focusOutPhone = async ({ target: { value } }) => {
    let valid = true;
    const newPhoneNumber = parsePhoneNumber(value, "IL");
    if (newPhoneNumber) {
      valid = newPhoneNumber.isValid();
    }
  };

  // const blurHandler = (e) => {
  //   if (e.includes("ראש העיו")) {
  //     alert("לא ראש העין");
  //   } else {
  //     alert(" ראש העין");
  //   }
  // };

  const handleModalClose = () => {
    setInvalidAddresspopUpMessage(false);
  };

  const handleLogout = (e) => {
    // alert("logout");
    localStorage.removeItem("access_token_client");
    setValidToken(false);
    setOptions("username");
    navigate("/");
  };

  useEffect(() => {
    if (localStorage.getItem("access_token_client")) {
      try {
        const getDetailsOfBusiness = async () => {
          try {
            var cus_id = null;
            const result = await axios.get(
              "/pogo_connect_api/server/web/index.php/customers/get_details",
              {
                headers: {
                  //   Authorization: `Bearer ${validToken?.access_token}`,
                  Authorization: `Bearer ${_validToken?.access_token}`,
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                },
              }
            );
            // if (result.data.message === "No customer") {
            //   allDetailsofBusinnes.fullName = "";
            //   allDetailsofBusinnes.phone = "";
            //   allDetailsofBusinnes.smsPhone = "";
            //   allDetailsofBusinnes.businnesSpectalization = "";
            //   allDetailsofBusinnes.addressCollection = "";
            //   allDetailsofBusinnes.cardNumber = "";
            //   allDetailsofBusinnes.expiryDate = "";
            //   allDetailsofBusinnes.id = "";
            //   allDetailsofBusinnes.mail = "";
            //   allDetailsofBusinnes.fullNameCardOwner = "";
            // }

            // console.log(result);
            if(result.data.stripe_id) {
              cus_id = parseInt(result.data.stripe_id);
              setSumitCusId(cus_id);
            }
            if (result.data.card_number) {
              setCloneResultData(_.cloneDeep(result.data.card_number));
            }
            allDetailsofBusinnes.fullName = result.data.customer_name
              ? result.data.customer_name
              : "";
            allDetailsofBusinnes.phone = result.data.connect_driver_phone
              ? result.data.connect_driver_phone
              : "";
            allDetailsofBusinnes.smsPhone = result.data.sms_phone
              ? result.data.sms_phone
              : "";
            allDetailsofBusinnes.businnesSpectalization = result.data
              .business_field
              ? result.data.business_field
              : "";
            allDetailsofBusinnes.addressCollection = result.data
              .business_address.formatted_address
              ? result.data.business_address.formatted_address
              : "";
            allDetailsofBusinnes.cardNumber = result.data.card_number
              ? result.data.card_number
              : "";
            allDetailsofBusinnes.expiryDate = result.data.card_exp_date
              ? result.data.card_exp_date
              : "";
            allDetailsofBusinnes.id = result.data.identity_number
              ? result.data.identity_number
              : "";
            allDetailsofBusinnes.stripe_id = result.data.stripe_id
              ? result.data.stripe_id
              : "";
            allDetailsofBusinnes.mail = result.data.email_address
              ? result.data.email_address
              : "";
            allDetailsofBusinnes.fullNameCardOwner = result.data.card_owner_name
              ? result.data.card_owner_name
              : "";

            setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
            setLng_lat({
              lat: result.data.business_address.lat
                ? result.data.business_address.lat
                : "",
              lng: result.data.business_address.lng
                ? result.data.business_address.lng
                : "",
            });
          } catch (error) {
            console.log(error);
          }
        };
        const customer_name = JSON.parse(
          localStorage.getItem("access_token_client")
        )
          ? JSON.parse(localStorage.getItem("access_token_client"))
              .customer_name
          : "";
        const access_token = JSON.parse(
          localStorage.getItem("access_token_client")
        )
          ? JSON.parse(localStorage.getItem("access_token_client")).access_token
          : "";
        setValidToken({
          access_token: access_token,
          customer_name: customer_name,
        });
        const _validToken = {
          access_token: access_token,
          customer_name: customer_name,
        };

        // setValidToken(true);
        console.log(customer_name);
        //get_validate_token();

        // if (
        //   setValidToken(JSON.parse(localStorage.getItem("access_token_client")))
        // ) {
        //   getDetailsOfBusiness().catch(console.error);
        // }

        //validToken && getDetailsOfBusiness().catch(console.error);
        _validToken.access_token !== "" &&
          _validToken.customer_name !== "" &&
          getDetailsOfBusiness().catch(console.error);
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  let update_methode = false;

  const handelOnSubmit = async (e) => {
    console.log(e);
    e.preventDefault();
    const validPhone = parsePhoneNumber(
      allDetailsofBusinnes.phone,
      "IL"
    ).isValid();
    const validMail = /\S+@\S+\.\S+/.test(allDetailsofBusinnes.mail);
    // console.log(errorsCreditCard);
    const customer_phone = JSON.parse(
      localStorage.getItem("access_customer_phone")
    )
      ? JSON.parse(localStorage.getItem("access_customer_phone")).customer_phone
      : "";
    if (
      allDetailsofBusinnes.fullName &&
      allDetailsofBusinnes.phone &&
      allDetailsofBusinnes.businnesSpectalization &&
      allDetailsofBusinnes.mail &&
      allDetailsofBusinnes.addressCollection &&
      allDetailsofBusinnes.id &&
      validPhone &&
      validMail &&
      errorsCreditCard.error == false
      // &&
      // errorsCreditCard.empty == false
    ) {
      setLoading(true);
      try {
        const response = await axios.post(
          "/pogo_connect_api/server/web/index.php/customers/update_details",
          {
            customer_name: allDetailsofBusinnes.fullName,
            email_address: allDetailsofBusinnes.mail,
            connect_driver_phone: allDetailsofBusinnes.phone,
            phone: customer_phone,
            sms_phone: allDetailsofBusinnes.smsPhone
              ? allDetailsofBusinnes.smsPhone
              : "",
            business_address: {
              lat: lng_lat.lat,
              lng: lng_lat.lng,
              formatted_address: allDetailsofBusinnes.addressCollection,
              cityName: exitAddressBusinnes[0]?.cityName,
              streetName: exitAddressBusinnes[0]?.streetName,
              streetNum: exitAddressBusinnes[0]?.streetNum,
            },
            business_field: allDetailsofBusinnes.businnesSpectalization,
            identity_number: allDetailsofBusinnes.id,
            card_owner_name: allDetailsofBusinnes.fullNameCardOwner,
            update_payment_method: !validToken ? true : update_credit_card,
          }
        );
        setShowSuccess(true);
        // setTimeout(() => {
        //   setShowSuccess(false);
        // }, 3000);
        // return
        if (update_credit_card) {
          setClientSecret("");
          setClientSecret(response.data.client_secret);
          setUpdate_credit_card(false);
        }
        if (response.data.client_secret && !update_credit_card) {
          console.log("Successful payment", response.data);
          // client_secret && setClientSecret(false)
          setClientSecret(response.data.client_secret);
          console.log({
            client: response.data.client_secret,
            client_secret: client_secret,
          });
          console.log(paymentMethodId);
        }
        if(response.data.stripe_id) {
          setSumitCusId(parseInt(response.data.stripe_id));
        }
        setLoading(false);
        //navigate("/connected");
        //window.location.href = "/connected";
        //}
      } catch (error) {
        console.log("Error", error);
        setErrorBusinnesDetails(true);
        setIdCreditCard(false);
      }

    } else {
      setErrorBusinnesDetails(true);
    }
  };

  useEffect(() => {
    const customer_phone = JSON.parse(
      localStorage.getItem("access_customer_phone")
    )
      ? JSON.parse(localStorage.getItem("access_customer_phone")).customer_phone
      : "";

    paymentMethodId &&
      axios
        .post(
          "/pogo_connect_api/server/web/index.php/customers/validate_payment_method",
          {
            phone: customer_phone,
            payment_method_id: paymentMethodId,
          }
        )
        .then(async (res) => {
          console.log(res);
          console.log(res.data);
          // if (!validToken) {
          localStorage.setItem(
            "access_token_client",
            JSON.stringify({
              access_token: res.data.access_token,
              customer_name: res.data.customer_name,
              customer_phone: allDetailsofBusinnes.phone,
            })
          );
          setValidToken(
            JSON.parse(localStorage.getItem("access_token_client"))
          );
          //If first login after thread of creating route save the route completely to delivery
          if(moveToDetailsOfBusiness && exitAddress && detailsOfPackage.length && getDataFromServer.track_id){
            setLoading(true);
            const result = await updateServer({
              detailsOfPackage,
              getDataFromServer,
              setGetDataFromServer,
              exitAddress,
              time,
              token,
              validToken,
              setValidToken
            });
            if(result === "ok")
              setIsUpdateServer(true);
          }
          setLoading(false);
          setMoveToDetailsOfBusiness(false);
          //window.location.href = "/connected";
          // }
        })
        .catch(function (error) {
          console.log(error);
          setPaymentMethodId(false);
        });
  }, [paymentMethodId]);
  //         console.log(e);

  const handleChangeBusinnesSpectalization = (e) => {
    allDetailsofBusinnes.businnesSpectalization = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
  };
  const handleChangeCardNumber = (e) => {
    allDetailsofBusinnes.cardNumber = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
  };
  const handleChangeExpiryDate = (e) => {
    allDetailsofBusinnes.expiryDate = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
  };
  const handleChangeCvc = (e) => {
    allDetailsofBusinnes.cvc = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
  };
  const handleChangeFullName = (e) => {
    // console.log(e);
    allDetailsofBusinnes.fullName = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
  };

  const handleChangePhone = ({ target: { value } }) => {
    let valid = true;
    const newPhoneNumber = parsePhoneNumber(value, "IL");

    if (newPhoneNumber) {
      valid = newPhoneNumber.isValid();
      if (valid) {
        value = newPhoneNumber.number;
      }
    }

    allDetailsofBusinnes.phone = value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });

    const phone = "phone";
    const bool = valid ? false : true;

    setErrorsPhoneName((prevState) => ({
      ...prevState,
      [phone]: bool,
    }));
  };

  const handleChangeSmsPhone = ({ target: { value } }) => {
    let valid = true;
    const newPhoneNumber = parsePhoneNumber(value, "IL");
    if (newPhoneNumber) {
      valid = newPhoneNumber.isValid();
      if (valid) {
        value = newPhoneNumber.number;
      }
    }
    allDetailsofBusinnes.smsPhone = value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
    const smsPhone = "phone";
    const bool = valid ? false : true;
    setErrorsPhoneName((prevState) => ({
      ...prevState,
      [smsPhone]: bool,
    }));
  };

  const handleChangeAddressCollection = (e) => {
    // if (e.includes("ראש העין")) {
    //   console.log("!!ראש העין");
    // }

    allDetailsofBusinnes.addressCollection = e;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
  };
  const handleChangeMail = (e) => {
    let errors = {};
    if (e.target.value) {
      errors = !/\S+@\S+\.\S+/.test(e.target.value);
      errors ? setErrorMail(true) : setErrorMail(false);
    } else {
      setErrorMail(false);
    }

    allDetailsofBusinnes.mail = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
  };
  const handleChangefullNameCardOwner = (e) => {
    allDetailsofBusinnes.fullNameCardOwner = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
  };

  const handleChangeId = (e) => {
    allDetailsofBusinnes.id = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
  };

  // console.log(allDetailsofBusinnes);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });

  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });
  const InputLabelProps = {
    display: "flex",
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "25px",
    textAlign: "right",
    color: "#0D152E",
    direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
  };
  const isRTL = (s) => {
    if (s !== "") {
      var ltrChars =
          "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
          "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
        rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
        rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]");

      return rtlDirCheck.test(s);
    }
    return true;
  };
  //   const handleSelect = async (address) => {
  //     try {
  //       const results = await geocodeByAddress(address);
  //       // console.log(results[0]);
  //       const addressComponents = results[0].address_components;
  //       // handleChangeAddressCollection && handleChangeAddressCollection(address);
  //       // changeAddressBusinnes && changeAddressBusinnes(address);
  //       // setSelect_address_businnes && setSelect_address_businnes(true);

  //       // if (addressComponents) {
  //       //   onChange && onChange(address);
  //       //   setColor("success");
  //       // }
  //       const filterCity = addressComponents.filter((address_component) =>
  //         address_component.types.includes("locality")
  //       );

  //       const filterStreet = addressComponents.filter((address_component) =>
  //         address_component.types.includes("route")
  //       );
  //       const filterStreetNum = addressComponents.filter((address_component) =>
  //         address_component.types.includes("street_number")
  //       );

  //       // const latLng = await getLatLng(results[0]);
  //       // lng_lat && lng_lat(latLng);
  //       // setIsClickd && setIsClickd(true);

  //       // if (setExitaddress) {
  //       //   exitAddress.cityName = filterCity[0]?.long_name;
  //       //   exitAddress.streetName = filterStreet[0]?.long_name;
  //       //   exitAddress.streetNum = filterStreetNum[0]?.long_name;
  //       //   setExitaddress({ ...exitAddress });
  //       // }
  //       allDetailsofBusinnes.cityName = filterCity[0]?.long_name;
  //       allDetailsofBusinnes.streetName = filterCity[0]?.streetName;
  //       allDetailsofBusinnes.streetNum = filterCity[0]?.streetNum;
  //       setAllDetailsofBusinnes({ ...allDetailsofBusinnes });

  //       //   if (setExitAddressBusinnes && setSelect_address_businnes) {
  //       //     setExitAddressBusinnes(
  //       //       ...[
  //       //         {
  //       //           cityName: filterCity[0]?.long_name,
  //       //           streetName: filterStreet[0]?.long_name,
  //       //           streetNum: filterStreetNum[0]?.long_name,
  //       //         },
  //       //       ]
  //       //     );
  //       //   }
  //       // setExitAddressBusinnes && onChange('')
  //       // selected &&
  //       //   selected([
  //       //     ...detailsOfPackage,
  //       //     {
  //       //       id_package: detailsOfPackage.length + 1,
  //       //       lat: latLng.lat,
  //       //       lng: latLng.lng,
  //       //       destination: address,
  //       //       cityName: filterCity[0]?.long_name,
  //       //       streetName: filterStreet[0]?.long_name,
  //       //       streetNum: filterStreetNum[0]?.long_name,
  //       //       contactName: "",
  //       //       contactPhone: "",
  //       //       payForStation: "",
  //       //       comment: "",
  //       //       currency_symbol_payForStation: "₪",
  //       //     },
  //       //   ]);
  //       // selected && onChange("");
  //       // console.log(detailsOfPackage);
  //     } catch (error) {
  //       console.error("Error", error);

  //       // return setErrorAutoComplet(t("ERROR_MESSAGE_AUTOCOMPLET"));
  //     }
  //   };
  const label = t("ADDRESS_COLLECTION");
  return (
    <><form
      onSubmit={handelOnSubmit}
      className="mobile_width_popUp"
      style={{ width: "100%", height: "auto", margin: "0 auto" }}
    >
      {/* {isMobile && <div className='cancel_mobile'><img className='Arrow_cancel' src={Arrow3} onClick={() => {}} /></div>} */}
      <CssBaseline />

      <div
        className="divPopUp"
        style={{
          maxWidth: isTabletOrMobile ? "445px" : "545px",
          height: isMobile && "auto",
        }}
      >
        <div style={{ width: "100%" }} className="moreDetailsHead">
          {" "}
          <h2
            style={{ textAlign: isMobile && "right", width: "100%" }}
            className="h2HeaderMoreDetails"
          >
            {t("BUSINNES_DETAILS")}
          </h2>
        </div>
        <div style={{ width: "100%" }}>
          <div
            className="margin mobile_flex col_revers_mobile"
            style={{
              gap: !isMobile && "24px",
              marginBottom: !isMobile && "25px",
            }}
          >
            <CacheProvider
              value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
            >
              <TextField
                error={!!errorsPhoneName.phone}
                helperText={errorsPhoneName.phone == true &&
                  t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME_VALIDPHON")}
                onFocus={() => setErrorsPhoneName(false)}
                value={allDetailsofBusinnes.phone ? allDetailsofBusinnes.phone : ""}
                onChange={handleChangePhone}
                label={t("DELIVER_CONTENT_PHONE_NUM")}
                onBlur={focusOutPhone}
                autoComplete="off"
                placeholder={t("DELIVER_CONTENT_PHONE_NUM")}
                sx={{
                  width: "50%",
                  height: !isMobile ? "81px" : "96px",
                  "& .MuiFormHelperText-root": { margin: isMobile && "0" },
                  "& .MuiOutlinedInput-root": {
                    height: "71px",
                    fontFamily: "Heebo",
                    fontSize: "16px",

                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                id="outlined-size-small"
                InputProps={{
                  style: {
                    height: "71px",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "17px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                    direction: ltrTheme.direction === "ltr" && isRTL("") ? "rtl" : "ltr",
                  },
                }}
                // InputProps={{ style: InputLabelProps }}
                InputLabelProps={{
                  style: InputLabelProps,
                  shrink: true,
                }} />
            </CacheProvider>
            <CacheProvider
              value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
            >
              <TextField
                label={t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME")}
                id="demo-helper-text-misaligned-no-helper"
                onChange={handleChangeFullName}
                // autoComplete='off'
                placeholder={t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME")}
                value={allDetailsofBusinnes.fullName
                  ? allDetailsofBusinnes.fullName
                  : ""}
                inputProps={{ height: "71px" }}
                // style={{marginBottom: isMobile &&"25px"}}
                sx={{
                  ml: 0,
                  width: "50%",
                  height: !isMobile ? "81px" : "96px",
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Heebo",
                    fontSize: "16px",
                    height: "71px",
                    // marginBottom: isMobile && '25px',
                    "& fieldset": {
                      // height:"71px",
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                    // '& .MuiOutlinedInput-notchedOutline': {
                    //     border: 'none'
                    //   }
                  },
                }}
                InputProps={{
                  // startAdornment: <InputAdornment position="start">₪</InputAdornment>,
                  style: {
                    height: "71px",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "17px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                    flexDirection: "row-reverse",
                    direction: ltrTheme.direction === "ltr" && isRTL("") ? "rtl" : "ltr",
                  },
                }}
                InputLabelProps={{
                  style: InputLabelProps,
                  shrink: true,
                }} />
            </CacheProvider>
          </div>

          <CacheProvider
            value={ltrTheme.direction === "ltr" && isRTL("") ? cacheRtl : cacheLtr}
          >
            <div style={{ marginBottom: !isMobile && "25px" }}>
              <TextField
                // required
                placeholder={t("BUSINNES_SPECIALIZATION")}
                autoComplete="off"
                onChange={handleChangeBusinnesSpectalization}
                value={allDetailsofBusinnes.businnesSpectalization
                  ? allDetailsofBusinnes.businnesSpectalization
                  : ""}
                label={t("BUSINNES_SPECIALIZATION")}
                id="outlined-size-small"
                sx={{
                  width: "100%",
                  height: !isMobile ? "81px" : "96px",
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Heebo",
                    fontSize: "16px",
                    height: "71px",
                    "& MuiOutlinedInput-input": {},
                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    height: "71px",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "17px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                    direction: ltrTheme.direction === "ltr" && isRTL("") ? "rtl" : "ltr",
                  },
                }}
                InputLabelProps={{
                  style: {
                    margin: "auto",
                    display: "flex",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                  },

                  shrink: true,
                }} />
            </div>
          </CacheProvider>

          <CacheProvider
            value={ltrTheme.direction === "ltr" && isRTL("") ? cacheRtl : cacheLtr}
          >
            <div
              style={{
                marginBottom: !isMobile && "25px",
                height: !isMobile ? "81px" : "96px",
              }}
            >
              <LocationSearchInput
                setSelect_address_businnes={setSelect_address_businnes}
                lng_lat={setLng_lat}
                label={label}
                handleChangeAddressCollection={handleChangeAddressCollection}
                value={allDetailsofBusinnes.addressCollection}
                exitAddressBusinnes={exitAddressBusinnes}
                setExitAddressBusinnes={(exitAddressBusinnes) => setExitAddressBusinnes(exitAddressBusinnes)} />
            </div>
          </CacheProvider>
          <div
            style={{
              marginBottom: !isMobile && "25px",
              height: isMobile && "96px",
            }}
          >
            <CacheProvider
              value={ltrTheme.direction === "ltr" && isRTL("") ? cacheRtl : cacheLtr}
            >
              <TextField
                // autoComplete='off'
                error={!!errorMail}
                helperText={errorMail == true &&
                  allDetailsofBusinnes.mail &&
                  t("STEPS_NUM_4_ABOUT_YOU_INPUT_MAIL_VALIDPHON")}
                onFocus={() => setErrorMail(false)}
                onChange={handleChangeMail}
                value={allDetailsofBusinnes.mail ? allDetailsofBusinnes.mail : ""}
                label={t("MAIL")}
                placeholder={t("MAIL")}
                type="email"
                id="outlined-size-small"
                sx={{
                  width: "100%",
                  height: !isMobile ? "81px" : "96px",
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Heebo",
                    fontSize: "16px",
                    height: "71px",
                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    height: "71px",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "17px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                    direction: ltrTheme.direction === "ltr" && isRTL("") ? "rtl" : "ltr",
                  },
                }}
                InputLabelProps={{
                  style: {
                    margin: "auto",
                    display: "flex",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                  },

                  shrink: true,
                }} />
            </CacheProvider>
          </div>
          <CacheProvider
            value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
          >
            <TextField
              error={!!errorsPhoneName.smsPhone}
              helperText={errorsPhoneName.smsPhone === true &&
                t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME_VALIDPHON")}
              onFocus={() => setErrorsPhoneName(false)}
              value={allDetailsofBusinnes.smsPhone
                ? allDetailsofBusinnes.smsPhone
                : ""}
              onChange={handleChangeSmsPhone}
              label={t("SMS_CONTENT_PHONE_NUM")}
              onBlur={focusOutPhone}
              autoComplete="off"
              placeholder={t("SMS_CONTENT_PHONE_NUM")}
              sx={{
                width: "100%",
                height: !isMobile ? "81px" : "96px",
                "& .MuiFormHelperText-root": { margin: isMobile && "0" },
                "& .MuiOutlinedInput-root": {
                  height: "71px",
                  fontFamily: "Heebo",
                  fontSize: "16px",
                  "& fieldset": {
                    direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                  },
                },
              }}
              //id="outlined-size-small"
              InputProps={{
                style: {
                  height: "71px",
                  fontFamily: "Heebo",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "17px",
                  lineHeight: "25px",
                  textAlign: "right",
                  color: "#0D152E",
                  direction: ltrTheme.direction === "ltr" && isRTL("") ? "rtl" : "ltr",
                },
              }}
              // InputProps={{ style: InputLabelProps }}
              InputLabelProps={{
                style: InputLabelProps,
                shrink: true,
              }} />
          </CacheProvider>
          {errorBusinnesDetails && (
            <ErrorAlert setErrorBusinnesDetails={setErrorBusinnesDetails} />
          )}

          {/* <div style={{ width: "100%" }} className="moreDetailsHead">
            {" "}
            <h2
              style={{ textAlign: isMobile && "right", width: "100%" }}
              className="h2HeaderMoreDetails"
            >
              {t("PAYMENT_DETAILS")}
            </h2>
          </div> */}

          {/* <StripeContainer setErrorBusinnesDetails={setErrorBusinnesDetails} /> */}

          {/* <div
            style={{
              marginBottom: !isMobile && "25px",
              height: isMobile && "96px",
            }}
          >
            <CacheProvider
              value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
            >
              <TextField
                placeholder={t("CREDIT_NAME")}
                autoComplete="off"
                onChange={handleChangefullNameCardOwner}
                value={allDetailsofBusinnes.fullNameCardOwner
                  ? allDetailsofBusinnes.fullNameCardOwner
                  : ""}
                label={t("CREDIT_NAME")}
                id="outlined-size-small"
                sx={{
                  width: "100%",
                  height: !isMobile ? "81px" : "96px",
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Heebo",
                    fontSize: "16px",
                    height: "71px",
                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    height: "71px",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "17px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                    direction: ltrTheme.direction === "ltr" ? "rtl" : "ltr",
                  },
                }}
                InputLabelProps={{
                  style: {
                    margin: "auto",
                    display: "flex",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                  },

                  shrink: true,
                }} />
            </CacheProvider>
          </div> */}

          {/* <div style={{ marginBottom: "45px" }}>
            <CacheProvider
              value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
            >
              <TextField
                placeholder={t("ID")}
                autoComplete="off"
                onChange={handleChangeId}
                value={allDetailsofBusinnes.id ? allDetailsofBusinnes.id : ""}
                label={t("ID")}
                id="outlined-size-small"
                sx={{
                  width: "100%",
                  height: "81px",
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Heebo",
                    fontSize: "16px",
                    height: "71px",
                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                inputProps={{
                  style: {
                    height: "71px",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "17px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                    direction: ltrTheme.direction === "ltr" ? "rtl" : "ltr",
                  },
                }}
                InputLabelProps={{
                  style: {
                    margin: "auto",
                    display: "flex",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                  },

                  shrink: true,
                }} />
            </CacheProvider>
          </div> */}
        </div>
      </div>

      <div
        className="widthActions"
        style={{
          // position: isMobile && location.pathname == '/details' && 'fixed',
          // zIndex: isMobile && '1000',
          bottom: isMobile && 0,
          maxWidth: isMobile ? "445px" : "545px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile && "column",
            alignItems: isMobile && "center",
            justifyContent: isMobile ? "center" : "space-between",
            marginBottom: "20px",
          }}
        >
          {!loading ? (
            <Button
              type="submit"
              style={{
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "17px",
                lineHeight: "25px",
                textAlign: "center",
                color: "#FFFFFF",
                height: "62px",
                textTransform: "none",
                background: "#583DFF",
                width: isMobile
                  ? "100%"
                  : location.pathname == "/details" && "100%",
                margin: isMobile && "0px",
              }}
              variant="contained"
              className="saveMoreDetails"
            >
              {location.pathname !== "/details" ? t("ENTRANCE") : t("SAVE")}{" "}
            </Button>
          ) : (
            <LoadingButton
              variant="contained"
              className="saveMoreDetails"
              sx={{
                height: "62px",
                textTransform: "none",
                background: "#583DFF",
                width: isMobile
                  ? "100%"
                  : location.pathname == "/details"
                    ? "100%"
                    : "71%",
                margin: isMobile && "0px",
              }}
              loading={loading}
            ></LoadingButton>
          )}
          {!isMobile && location.pathname !== "/details" && (
            <Button
              style={{ height: "62px", textTransform: "none" }}
              variant="outlined"
              className="cancelMoreDetails"
              onClick={() => {
                window.location.href = "/";
                setMoveToDetailsOfBusiness(false);
              } }
            >
              {t("ADD_DETAILS_WINDOW_BTN_CANCEL")}
            </Button>
          )}
          {/* {isMobile && <div style={{ marginTop: '36px', marginBottom: '33px' }}>
                  <img className="btn imgMoreDetailsHidden" style={{
                      visibility: "visible",
                      cursor: "pointer",

                  }}
                      onClick={() => { }} src={''} alt="trash" />
                  <span className="imgMoreDetailsShow">{t("DELETE_STATION_MOBILE")}</span>
              </div>} */}
        </div>
        {showSuccess && (
          <p style={{ height: "inherit" }} class="p_label alert alert-success">
            !הפרטים נשמרו בהצלחה{" "}
          </p>
        )}
        {invalidAddresspopUpMessage && (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={invalidAddresspopUpMessage}
            // onClose={() =>
            //      setPopUpMessage(false)
            //     }
            // closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              // onClick: { handleModalBackdrop },
              timeout: 500,
            }}
          >
            <Fade in={invalidAddresspopUpMessage}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: isMobile ? "100%" : 400,
                  textAlign: "center",
                  bgcolor: "background.paper",
                  border: "2px solid #583DFF",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={handleModalClose}
                ></button>
                <Typography
                  style={{
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "32px",
                    // textAlign: 'right',
                    letterSpacing: "-2px",
                    color: "#0D152E",
                  }}
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  כתובת עסק זו עדיין לא נתמכת בשרות העסקי. השרות כרגע לעסקים
                  בראש העין בלבד. ניתן לעבור לשרות הפרטי
                  {/* {t("POPUPMESSAGE_HEADER")} */}
                </Typography>
                <Typography
                  style={{
                    fontSize: "17px",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    color: "#81838C",
                  }}
                  id="transition-modal-description"
                  sx={{ mt: 2 }}
                >
                  <button onClick={handleLogout}>מעבר לשירות פרטי</button>
                  {/* {t("POPUPMESSAGE_CONTENT")} */}
                </Typography>
              </Box>
            </Fade>
            {/* <Modal.Footer>
              <Button variant="secondary">Close</Button>
              <Button variant="primary">Save changes</Button>
            </Modal.Footer> */}
          </Modal>
        )}
      </div>
    </form>
    <PaymentForm setErrorBusinnesDetails={setErrorBusinnesDetails} /></>
  );
}
