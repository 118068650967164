import Header from "./component/homePage/header/header";
import DetailsOfPackage from "./component/homePage/detailsPackage/detailsOfPackage";
import AboutPogo from "./component/homePage/aboutPogo/about";
import CustomersRecommend from "./component/homePage/customersRecommend/customersRecommend";
import { Container } from "react-bootstrap";
import Footer from "./component/homePage/footer/footer";
import BackToDetails from "./component/homePage/detailsPackage/backToDetails/backToDetails";
import { useState, useEffect, useContext, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import { Button } from "@mui/material";
import ShipmentTracking from "./component/homePage/detailsPackage/MultiSteps/stepsNum/shipment_tracking";
import "./App.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MultiSteps from "./component/homePage/detailsPackage/MultiSteps/MultiSteps";
import Summary from "./component/homePage/detailsPackage/MultiSteps/stepsNum/summary";
import HomePage from "./homepage";
import { DataContext } from "./dataContext";
import LogoConnect from "./component/homePage/logo_connect_header/logo_connect_header";
import BusinessDetailsPackage from "./component/homePage/detailsPackage/businessDetailsPackage/businessDetailsPackage";
import DeliveryHistory from "./component/homePage/deliveryHistory/deliveryHistory";
import DetailsOfBusiness from "./component/homePage/detailsOfBusiness/details_business";
import {
  ProtectedRoutesConnected,
  ProtectedRoutesDisConnected,
} from "./protectRoutes";
import StripeContainer from "../src/component/homePage/login/payment/elements";
import { useMediaQuery } from "react-responsive";
//axios.defaults.baseURL = "https://w8.logi-way.com/";
axios.defaults.baseURL = "https://api.getpogo.app/";
//  axios.defaults.baseURL="http://localhost:3000"

function App({}) {
  const {
    showButton,
    setShowButton,
    showSteps,
    setShowSteps,
    validToken,
    setValidToken,
    moveToDetailsOfBusiness,
    setMoveToDetailsOfBusiness
  } = useContext(DataContext);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  // console.log(validToken);

  const token = JSON.parse(localStorage.getItem("access_token_client"));

  useEffect(() => {
    const getAuth = async () => {
      if (token) {
        // setValidToken(token)
        try {
          const headers = {
            Authorization: `Bearer ${token?.access_token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          };
          const result = await axios
            .get(
              "pogo_connect_api/server/web/index.php/customers/validate_token",
              { headers }
            )
            .then((result) => {
              console.log(result);
              setValidToken(token);
              // if (result.data.status === 30) {
              //   // setStatusOfPackage(3);
              // }
            });

          console.log(result);
        } catch (error) {
          setValidToken(false);
          console.log(error);
        }
      } else {
        return;
      }
    };
    getAuth().catch(console.error);
  }, []);

  const refAuto = useRef();
  // לחצן בצד המסך יופיע רק בגובה מסוים
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (showSteps) {
        setShowButton(false);
      }
      // if (isMobile) {
      //   setShowButton(false);
      // }
      if (window.pageYOffset > 300 && !showSteps) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, [showSteps]);
  // console.log(window.location.pathname);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container fluid className="widthContainer">
      {/* <Router>
        <Routes>
         

        </Routes>
      </Router> */}

      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route element={<ProtectedRoutesDisConnected />}>
            <Route
              exact
              path="/connected"
              element={
                <div>
                  <LogoConnect />
                  {/* <BusinessDetailsPackage /> */}
                  {!moveToDetailsOfBusiness && (<DetailsOfPackage
                    showSteps={showSteps}
                    setShowSteps={setShowSteps}
                  />)}
                </div>
              }
            />

            <Route
              exact
              path="/details"
              element={
                <div>
                  <LogoConnect />
                  <DetailsOfBusiness />
                </div>
              }
            />

            <Route
              exact
              path="/history-delivery"
              element={
                <div>
                  <LogoConnect />
                  <DeliveryHistory />
                </div>
              }
            />
          </Route>

          <Route element={<ProtectedRoutesConnected />}>
            <Route
              exact
              path="/"
              element={
                <HomePage showSteps={showSteps} setShowSteps={setShowSteps} />
              }
            />

            {/* <Route
            exact
            path="/:token"
            element={

              <DetailsOfPackage
                showSteps={showSteps}
                setShowSteps={setShowSteps}
              >
                {" "}
                <MultiSteps />{" "}
              </DetailsOfPackage>
              
            }
          /> */}
          </Route>

          {/* <Route
                exact
                path="/element"
                element={
                  <StripeContainer
                  
                  />
                
                }
              /> */}
        </Routes>
      </Router>

      {showButton && !isMobile && (
        <div>
          <button className="back-to-top" onClick={scrollToTop}>
            &#8679;
          </button>
        </div>
      )}
    </Container>
  );
}

export default App;
